import styles from './layout.module.css'

import { Outlet } from "react-router"
import { SwapFooter } from "../components/inpage/footers/swapFooter"
import { BlockNumberReadout } from '../components/inpage/overlay/blockNumberReadout'
import { SwapContextProvider } from '../hooks/context/swapContext'


export const Layout = () => {
    return (
        <SwapContextProvider>
            <div className={styles.container}>
                <BlockNumberReadout />
                <Outlet/>
            </div>
            <SwapFooter />
        </SwapContextProvider>
    )
}

export default Layout
