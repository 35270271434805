
import ETHLogo from '../../images/tokens/devel/ETH.png'
import UNILogo from '../../images/tokens/devel/UNI.png'


export const GoeliTokens = [
    {
        name: 'Ethereum',
        symbol: 'ETH',
        decimals: 18,
        address: '0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6',
        logoUrl: ETHLogo,
        isNativeToken: true
    },
    {
        name: 'Uniswap',
        symbol: 'UNI',
        decimals: 18,
        address: '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984',
        logoUrl: UNILogo
    }
]
