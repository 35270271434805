import { createContext, useContext } from "react";


export const PopupContext = createContext<PopupContextType>({activePopup: 'None', setPopup: () => {}, close: () => {}})

export default function usePopups() {
    return useContext(PopupContext)
}

type PopupContextType = {
    activePopup: ActivePopup
    setPopup: (popup: ActivePopup) => void
    close: () => void
}
export type ActivePopup = 'None' | 'ConfirmSwap' | 'TokenSelection' | 'ConfirmApproval' | 'SwapFailed' | 'ApproveSwap' | 'SwapSuccess' | 'CurrencySelect' | 'BlockConfirmation' | 'SignIn' | 'SignUp' | 'AddWallet' | 'ConfirmEmail'
