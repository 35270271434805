import { NavLink } from 'react-router-dom'
import styles from './notFound.module.css'

export const NotFound = () => {
    return (
        <div className={styles.container}>
            <h1>404 - Not Found</h1>
            <p>The page you're trying to visit doesn't seem to exist</p>
            <NavLink to='/'>Back home</NavLink>
        </div>
    )
}

export default NotFound
