import { ReactNode } from 'react'
//import styles from './tradeReadout.module.css'




const CenterPiece = (props: {children?: ReactNode }) => {
    return (
        <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', margin: '0em', padding: '0', marginBottom: '0.2em', marginTop: '0.2em'}}>
            {props.children}
        </div>
    )
}



type StatReadoutProps = {
    title: string
    content: string
    color?: string
}

const StatReadout = (props: StatReadoutProps) => {
    return (
        <CenterPiece>
            <h4 style={{margin: 'inherit'}}>{props.title}</h4>
            <p style={{margin: 'inherit', color: props.color ? props.color : 'inherit'}}>{props.content}</p>
        </CenterPiece>
    )
}


export const TradeReadout = (props: {priceReadoutContent: string, liquidityProtocolReadoutContent: string}) => {
    return (
        <>
            <StatReadout title="Price" content={props.priceReadoutContent}/>
            <StatReadout title="Slippage Tolerance" content="0%" color="lightgreen"/>
            <StatReadout title="Liquidity Protocol" content={props.liquidityProtocolReadoutContent} color={props.liquidityProtocolReadoutContent === 'V2' ? "red" : "lightgreen"}/>
        </>
    )
}

export default TradeReadout
