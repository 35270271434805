import styles from './landingHeader.module.css'
import glassyStyles from '../../../styles/neumorphic/glassy.module.css'
import shinyStyles from '../../../styles/shine/shiny.module.css'

import FullBSwapLogo from '../../../images/boda/BodaSwap.png'

import { ReactNode, useEffect, useState } from 'react';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { useAccount, useDisconnect } from 'wagmi';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import SubNavMenu from './subnav'
import UserProfile from './profile/userProfile';


export type SubMenuNavLink = {
    icon: ReactNode
    name: string
    description: string
    path: string
}
const tradeLinks: Readonly<SubMenuNavLink[]> = [
    {
        icon: <FontAwesomeIcon icon={solid('sync-alt')} />,
        name: 'Swap',
        description: 'Swap tokens with no slippage',
        path: '/swap'
    },
    {
        icon: <FontAwesomeIcon icon={solid('truck-ramp')} />,
        name: 'Onramp',
        description: 'Convert fiat into crypto',
        path: '/swap'
    },
    {
        icon: <FontAwesomeIcon icon={solid('bridge')} />,
        name: 'Bridge',
        description: 'Cross-chain crypto transactions',
        path: '/swap'
    },
    {
        icon: <FontAwesomeIcon icon={solid('exchange')} />,
        name: 'Exchange',
        description: 'Powerful trading tools',
        path: '/swap'
    }
] as const
const ecosystemLinks: Readonly<SubMenuNavLink[]> = [
    {
        icon: <FontAwesomeIcon icon={solid('server')} />,
        name: 'Dashboard',
        description: 'View and claim your BODAV2 rewards',
        path: 'https://bodatoken.app/dashboard'
    },
    {
        icon: <FontAwesomeIcon icon={solid('coin')} />,
        name: 'BODAV2',
        description: 'BODAV2 Token on BNB Chain',
        path: 'https://bscscan.com/address/0xdc847755343c3a2b94d6afc0aae57651e1b14064'
    },
    {
        icon: <FontAwesomeIcon icon={solid('sync-alt')} />,
        name: 'BodaSwap',
        description: 'Multi-chain Zero Slippage DEX',
        path: '/swap'
    },
    {
        icon: <FontAwesomeIcon icon={solid('ticket')} />,
        name: 'Lottery',
        description: 'BODAV2 Web3 Lottery',
        path: '/swap'
    },
    {
        icon: <FontAwesomeIcon icon={solid('tractor')} />,
        name: 'Staking',
        description: 'Earn passive income rewards',
        path: '/swap'
    },
    {
        icon: <FontAwesomeIcon icon={solid('tractor')} />,
        name: 'NFTs',
        description: 'Unique BODAV2 inspired NFTs',
        path: '/swap'
    }
] as const



export default function GlobalHeader() {
    const { pathname } = useLocation()

    const [scrollPosition, setScrollPosition] = useState(0)
    const handleScroll = () => {
        setScrollPosition(window.scrollY)
    }
    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true })

        return () => window.removeEventListener('scroll', handleScroll)
    }, [])

    const { open } = useWeb3Modal()
    const { address } = useAccount()
    const { disconnect } = useDisconnect()

    const [tradeSubNavOpen, setTradeSubNavOpen] = useState(false)
    const [ecosystemSubNavOpen, setEcosystemSubNavOpen] = useState(false)

    const [subNavTimeout, setSubNavTimeout] = useState<NodeJS.Timeout | null>(null)


    return (
        <header className={`${styles.header} ${scrollPosition > 0 ? styles.blurry : ''}`}>
            <NavLink to='/'><img src={FullBSwapLogo} /></NavLink>
            <div className={styles.nav}>
                <div onMouseEnter={() => {
                    setTradeSubNavOpen(true)
                    setEcosystemSubNavOpen(false)

                    if (subNavTimeout !== null)
                        clearTimeout(subNavTimeout)

                }} onMouseLeave={() => {

                    setSubNavTimeout(setTimeout(() => setTradeSubNavOpen(false), 300))
                }}>
                    Trade <FontAwesomeIcon icon={solid('caret-down')}/>
                    <SubNavMenu open={tradeSubNavOpen} links={tradeLinks} />
                </div>
                <div onMouseEnter={() => {
                    setEcosystemSubNavOpen(true)
                    setTradeSubNavOpen(false)

                    if (subNavTimeout !== null)
                        clearTimeout(subNavTimeout)

                }} onMouseLeave={() => {
                    if (subNavTimeout !== null)
                        clearTimeout(subNavTimeout)

                    setSubNavTimeout(setTimeout(() => setEcosystemSubNavOpen(false), 300))
                }}>
                    Ecosystem <FontAwesomeIcon icon={solid('caret-down')}/>
                    <SubNavMenu open={ecosystemSubNavOpen} links={ecosystemLinks} />
                </div>
                <NavLink to='/pro' style={{color: 'lightgreen'}} className={pathname === '/pro' ? styles.active : styles.inactive}>Pro</NavLink>
            </div>

            <div style={{fontSize: '0.8em', marginLeft: 'auto'}}>
                {/* <div className={styles.pcsReadout}>
                    <img src={BODASwapLogo} />
                    <p>$1.28</p>
                </div>
                <div className={styles.pcsReadout}>
                    <img src='favicon.ico' />
                    <p>$0.00000000053515</p>
                </div>

                <div className={styles.pcsReadout}>
                    <img src={BNBLogo} />
                    <p>$636.46</p>
                </div>
                <div className={styles.theOtherPcsReadout}>
                    <img src={BNBIcon} />
                    <p>BNB Chain</p>
                    <FontAwesomeIcon icon={solid('caret-down')} />
                </div> */}

                <button 
                    onClick={() => (address ? disconnect() : open())}
                    className={`${glassyStyles.darkGlassy} ${styles.button}`}>

                    <div className={`${styles.shinyCover} ${shinyStyles.shiny}`}/>
                    {address ? address.substring(0, 2).concat('...').concat(address.substring(address.length - 4, address.length)) : 'Connect Wallet'}
                </button>

                <UserProfile/>
            </div>

            <hr className={scrollPosition > 0 ? '' : styles.invisible}/>
        </header>
    )
}
