import { useAccount } from 'wagmi'
import { NetworkReadout } from '../../readouts/networkReadout'
import { SwapSettingsButton, SwapSettingsButtonContent } from './minis/swapSettingsButton'
import styles from './swapButtons.module.css'


export type SwapSettingsButtonStatuses = {
    disableSwapSides: boolean
}

export type SwapButtonsProps = {
    setters: {
        setSwapSides: React.Dispatch<React.SetStateAction<boolean>>,
        setViewChart: React.Dispatch<React.SetStateAction<boolean>>,
        handleSettingsClicked: () => void,
        handleRefreshClicked: () => void
    },
    disableButtons: SwapSettingsButtonStatuses
}

export const SwapButtons = (props: SwapButtonsProps) => {
    const { address } = useAccount()

    return (
        <div className={styles.container}>
            <SwapSettingsButton disabled={props.disableButtons.disableSwapSides} content={SwapSettingsButtonContent.swapSides} onClick={(e) => { props.setters.setSwapSides(x => !x) }} />
            <SwapSettingsButton content={SwapSettingsButtonContent.toggleChart} onClick={(e) => { props.setters.setViewChart(x => !x) }} />

            <div style={{marginLeft: 'auto'}}/>
            {address && <NetworkReadout />}
            <div style={{marginLeft: 'auto'}}/>

            <SwapSettingsButton onClick={props.setters.handleRefreshClicked} content={SwapSettingsButtonContent.refresh} />
            <SwapSettingsButton onClick={props.setters.handleSettingsClicked} content={SwapSettingsButtonContent.settings} />
        </div>
    )
}

export default SwapButtons
