import PCSToken from "../types/token"
import { ArbitrumOneTokens } from "./lists/arbitrumOneTokens"
import { BinanceTokens } from "./lists/binanceTokens"
import { EthereumTokens } from "./lists/ethereumTokens"
import { WRAPPED_NATIVE_CURRENCY } from "@uniswap/smart-order-router"
import { Token } from '@uniswap/sdk-core'
import { PulseTokens } from "./lists/pulseTokens"
import { PolygonTokens } from "./lists/polygonTokens"
import { CronosTokens } from "./lists/cronosTokens"
import { GoeliTokens as GoerliTokens } from "./lists/goerliTokens"
import { AvalancheTokens } from "./lists/avalancheTokens"
import { BaseTokens } from "./lists/baseTokens"


// @TODO Refactor into being part of SupportedNetworks
export type TokenList = PCSToken[]
export const TokenLists = {
    1: EthereumTokens,
    5: GoerliTokens,
    25: CronosTokens,
    56: BinanceTokens,
    137: PolygonTokens,
    369: PulseTokens,
    8453: BaseTokens,
    43114: AvalancheTokens,
    42161: ArbitrumOneTokens
}


export function isWrappedCoin(tokenAddress: string, chainId: number) {
    const lowerAddress = tokenAddress.toLowerCase()

    return lowerAddress === ((WRAPPED_NATIVE_CURRENCY as any)[chainId] as Token | undefined)?.address.toLowerCase()
}
