import styles from './swapFailedPopup.module.css'

import BasePopup from '../../basePopup'
import usePopups from '../../../../../hooks/usePopups'
import { Link } from 'react-router-dom'
import glassyStyles from '../../../../../styles/neumorphic/glassy.module.css'
import shinyStyles from '../../../../../styles/shine/shiny.module.css'
import { FormEvent, useEffect, useState } from 'react'
import useBodaSwapApi from '../../../../../hooks/useBodaSwapApi'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'


export const SignInPopup = () => {
    const popups = usePopups()
    const api = useBodaSwapApi()

    const [loggingIn, setLoggingIn] = useState(false)
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [errorText, setErrorText] = useState<null | string>(null)
    const [success, setSuccess] = useState(false)

    useEffect(() => {
        setSuccess(false)
        setLoggingIn(false)
        setUsername('')
        setPassword('')
        setErrorText(null)
    }, [popups.activePopup])

    async function onLogIn(e: FormEvent) {
        e.preventDefault()

        setLoggingIn(true)

        try {
            await api.logIn(username, password)

            setErrorText(null)

            setSuccess(true)
            setTimeout(() => {
                setSuccess(false)

                popups.setPopup('None')
            }, 1000)
        }
        catch (e: any) {
            setErrorText(e?.message ?? 'Unknown error, please try again later')
        }
        finally {
            setLoggingIn(false)
        }
    }

    return (
        <BasePopup showing={popups.activePopup === 'SignIn'} idealWidth='20rem' minWidth='13rem' title='' hideButton handleClose={popups.close}>
            <div className={styles.container}>
                <div className={styles.textContainer}>
                    <h2>Login</h2>
                    <form onSubmit={onLogIn}>
                        <label htmlFor='username'>Username</label>
                        <input value={username} onChange={(e) => setUsername(e.target.value)} type='text' id='username' />

                        <label htmlFor='password'>Password</label>
                        <input value={password} onChange={(e) => setPassword(e.target.value)} type='password' id='password' />

                        {
                            success ? (
                                <div style={{display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center'}}>
                                    <FontAwesomeIcon size='2x' icon={solid('check-circle')} style={{marginTop: '0.5em'}} />
                                </div>
                            ) : (
                                loggingIn ? (
                                    <div style={{display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center'}}>
                                        <FontAwesomeIcon icon={solid('spinner-third')} color='lightgreen' className='fa-spin' style={{height: '2em', width: '2em'}}/>
                                    </div>
                                ) : (
                                    <button className={`${glassyStyles.darkGlassy} ${styles.button}`}>
                                        <div className={`${styles.shinyCover} ${shinyStyles.shiny}`}/>
                                        Log In
                                    </button>
                                )
                            )
                        }

                        <label className={styles.errorLabel}>{errorText && errorText}</label>
                    </form>
                </div>

                <Link to='/reset'>Forgot Login?</Link>
                <p>Don't have an account?   <a 
                                            className={styles.signUp} 
                                            onClick={() => {
                                                popups.setPopup('SignUp')
                                            }}>
                                                Sign Up
                                            </a>
                .</p>
            </div>
        </BasePopup>
    )
}
