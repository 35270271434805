import styles from './swapFailedPopup.module.css'

import BasePopup from '../basePopup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import usePopups from '../../../../hooks/usePopups'


type WaitingForConfirmationPopupProps = {
}
export const WaitingForConfirmationPopup = (props: WaitingForConfirmationPopupProps) => {
    const popups = usePopups()

    return (
        <BasePopup showing={popups.activePopup === 'BlockConfirmation'} idealWidth='20rem' minWidth='13rem' title='Confirming' hideButton handleClose={popups.close}>
            <div className={styles.textContainer}>
                <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                    <FontAwesomeIcon color='white' className={styles.icon} icon={regular('diagram-project')} />
                    <div style={{width: '2em'}} />
                    <FontAwesomeIcon color='white' className={styles.icon} icon={regular('lock')} />
                </div>

                <h2>Waiting For Confirmation</h2>

                <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                    <p>Waiting for block confirmations...</p>
                    <FontAwesomeIcon icon={solid('spinner-third')} color='lightgreen' className='fa-spin' style={{height: '2em', width: '2em'}}/>
                </div>
            </div>
        </BasePopup>
    )
}
