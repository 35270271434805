import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './switchButton.module.css'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'




export const SwitchButton = (props: { onClick: () => void }) => {
    return (
        <div className={styles.container}>
            <button onClick={props.onClick} className={styles.button}>
                <FontAwesomeIcon icon={solid('arrow-up-arrow-down')} color='white' />
            </button>
        </div>
    )
}

export default SwitchButton
