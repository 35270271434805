import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PCSToken from '../../../../types/token'
import BasePopup from '../basePopup'
import styles from './confirmApproveTokensPopup.module.css'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import usePopups from '../../../../hooks/usePopups'
import useSwap from '../../../../hooks/context/swapContext'

export const ConfirmApproveTokensPopup = () => {
    const popups = usePopups()

    return (
        <BasePopup showing={popups.activePopup === 'ConfirmApproval'} hideButton idealWidth='25rem' minWidth='13rem' title='Approve Tokens'>    
            <div className={styles.textContainer}>
                <h2>Please approve your tokens</h2>
                <p>You will need to complete <b>2</b> transactions: one for approval, one for swap. Please complete the transaction in your wallet to proceed.</p>

                <p style={{color: 'orange'}}><b>Please note:</b> The next step will not appear until <b>4</b> block confirmations are completed after the transaction is successful.</p>

                <p>This usually takes about 30 seconds. If the transaction does not complete within 5 minutes, refresh the page and try again.</p>

                <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                    <h3>Waiting for approval...</h3>
                    <FontAwesomeIcon icon={solid('spinner-third')} color='lightgreen' className='fa-spin' style={{height: '2em', width: '2em'}}/>
                </div>
            </div>
        </BasePopup>
    )
}
