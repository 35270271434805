import { ReactNode } from 'react'
import styles from './basePopup.module.css'
import CloseButton from '../ui/buttons/closeButton'
import HorizontalSeperator from '../ui/seperators/horizontalSeperator'
import { motion, AnimatePresence } from "framer-motion"

type BasePopupProps = {
    children?: ReactNode,

    minWidth?: string
    idealWidth?: string

    title?: string,
    handleClose?: () => void

    hideButton?: boolean

    showing: boolean
}

export const BasePopup = (props: BasePopupProps) => {
    const initialState = {
        backdropFilter: 'blur(0rem)',
        backgroundColor: 'rgba(0, 0, 0, 0)'
    }
    const animateState = {
        backdropFilter: 'blur(0.5rem)',
        backgroundColor: 'rgba(0, 0, 0, 0.2)'
    }
    const exitState = {
        backdropFilter: 'blur(0rem)',
        backgroundColor: 'rgba(0, 0, 0, 0)'
    }

    const initialContentState = {
        opacity: 0
    }
    const animateContentState = {
        opacity: 1
    }
    const exitContentState = {
        opacity: 0
    }

    return (
        <AnimatePresence>
            {props.showing && <motion.div initial={initialState} animate={animateState} exit={exitState} className={styles.container} onClick={(e) => { if (e.target === e.currentTarget) { if (props.handleClose) { props.handleClose() } } }}>
                <motion.div transition={{duration: 0.2}} initial={initialContentState} animate={animateContentState} exit={exitContentState} className={styles.popup} style={{minWidth: props.minWidth, width: props.idealWidth}}>
                    {props.title && (<>
                        <div className={styles.header}>
                            <h2>{props.title ?? 'Popup'}</h2>
                            <div style={{marginLeft: 'auto'}} />
                            {!props.hideButton && <CloseButton onClick={props.handleClose} />}
                        </div>

                        <HorizontalSeperator />
                    </>)}

                    {props.children}
                </motion.div>
            </motion.div>}
        </AnimatePresence>
    )
}

export default BasePopup
// TODO Change backdrop filter to :after css