import styles from './horizontalSeperator.module.css'


export const HorizontalSeperator = () => {
    return (
        <hr className={styles.seperator}/>
    )
}

export default HorizontalSeperator
