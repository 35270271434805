import BinanceChainLogo from '../../images/chains/BinanceChain.png'
import ArbitrumLogo from '../../images/chains/ARB.svg'
import EthereumLogo from '../../images/chains/ETH.svg'
import GoerliLogo from '../../images/chains/Goerli.webp'
import PulseLogo from '../../images/chains/PLS.png'
import MATICLogo from '../../images/chains/MATIC.svg'
import CronosLogo from '../../images/chains/CRO.svg'
import BaseLogo from '../../images/chains/BASE.png'
import AvaxLogo from '../../images/chains/AVAX.svg'


export type SupportedNetworkSchematic = {
    iconUrl: string
    name: string,
    swapContractAddress: string | undefined
}

export const SupportedNetworks = {
    1: {
        iconUrl: EthereumLogo,
        name: 'Ethereum',
        swapContractAddress: undefined
    } satisfies SupportedNetworkSchematic,
    5: {
        iconUrl: GoerliLogo,
        name: 'Goerli',
        swapContractAddress: '0x2755c8B3A79D9D7C23Ee31b3656CfCf34EAa3D84'
    } satisfies SupportedNetworkSchematic,
    25: {
        iconUrl: CronosLogo,
        name: 'Cronos',
        swapContractAddress: undefined
    } satisfies SupportedNetworkSchematic,
    56: {
        iconUrl: BinanceChainLogo,
        name: 'BNB Chain',
        swapContractAddress: '0x84bbd772423DFCaAFCeDCC26ef951011Bf31E5Ea'
    } satisfies SupportedNetworkSchematic,
    137: {
        iconUrl: MATICLogo,
        name: 'Polygon',
        swapContractAddress: '0xBE4544158495A9AF641b175C7398eBdA488d0164'
    } satisfies SupportedNetworkSchematic,
    369: {
        iconUrl: PulseLogo,
        name: 'Pulse Chain',
        swapContractAddress: undefined
    } satisfies SupportedNetworkSchematic,
    8453: {
        iconUrl: BaseLogo,
        name: 'Base Chain',
        swapContractAddress: undefined
    } satisfies SupportedNetworkSchematic,
    43114: {
        iconUrl: AvaxLogo,
        name: 'Avalanche',
        swapContractAddress: undefined
    } satisfies SupportedNetworkSchematic,
    42161: {
        iconUrl: ArbitrumLogo,
        name: 'Arbitrum One',
        swapContractAddress: '0xBE4544158495A9AF641b175C7398eBdA488d0164'
    } satisfies SupportedNetworkSchematic,
    11155111: {
        iconUrl: GoerliLogo,
        name: 'Sepolia ETH',
        swapContractAddress: ''
    }
}

export default SupportedNetworks
