import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PCSToken from '../../../../types/token'
import styles from './chartDisplay.module.css'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

export type ChartDisplayProps = {
    tokenA: PCSToken,
    tokenB: PCSToken,

    timeFrame: string
}

export const ChartDisplay = (props: ChartDisplayProps) => {
    return (
        <div className={styles.container}>
            <img className={styles.tokenLogo} src={props.tokenB.logoUrl} alt='' />
            <img className={styles.tokenLogo} src={props.tokenA.logoUrl} alt='' />

            <p className={styles.symbols}>{props.tokenB.symbol}/{props.tokenA.symbol}</p>

            <p className={styles.timeframeReadout}>-</p>

            <p className={styles.timeframeReadout}>{props.timeFrame}</p>

            <div style={{marginLeft: 'auto'}} />

            <FontAwesomeIcon icon={solid('expand')} color='white' cursor='pointer' />
        </div>
    )
}

export default ChartDisplay;
