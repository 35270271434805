import styles from './confirmSwapPopup.module.css'

import PCSToken from '../../../../types/token'
import BasePopup from '../basePopup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import ConfirmButton from '../../ui/buttons/confirmButton'
import { BigNumberish, ethers } from 'ethers'
import usePopups from '../../../../hooks/usePopups'
import useSwap from '../../../../hooks/context/swapContext'


const VisualReadout = (props: {tokens: {tokenA: PCSToken, tokenB: PCSToken}}) => {
    return (
        <div className={styles.visualReadoutContainer}>
            <img className={styles.tokenIcon} src={props.tokens.tokenA.logoUrl} alt='' />
            <FontAwesomeIcon className={styles.rightArrow} icon={solid('arrow-right')} />
            <img className={styles.tokenIcon} src={props.tokens.tokenB.logoUrl} alt='' />
        </div>
    )
}


export const ConfirmSwapPopup = () => {
    const popups = usePopups()

    const {
        tokens,

        inputValue,
        outputValue,

        performSwap: onConfirm,
    } = useSwap()
    const outputIsExact = false

    const quantities = {
        inputReadout: ethers.utils.formatUnits(inputValue, tokens.tokenA.decimals),
        outputReadout: ethers.utils.formatUnits(outputValue, tokens.tokenB.decimals)
    }

    return (
        <BasePopup showing={popups.activePopup === 'ConfirmSwap'} idealWidth='25rem' minWidth='13rem' handleClose={popups.close} title='Confirm Swap'>
            <VisualReadout tokens={tokens} />

            <div className={styles.textContainer}>
                <h2>You are swapping:</h2>
                <h4 style={{marginBottom: '0.2em'}}>{outputIsExact ? 'Approx.' : 'Exactly'} <span style={{color: '#238b52'}}>{quantities.inputReadout.toString()}</span> {tokens.tokenA.name}</h4>
                <p style={{margin: '0', padding: '0'}}>For</p>
                <h4 style={{marginTop: '0.2em'}}>{outputIsExact ? 'Exactly' : 'Approx.'} <span style={{color: '#238b52'}}>{quantities.outputReadout.toString()}</span> {tokens.tokenB.name}</h4>

                <p>(You may receive less if swap tokens have a tax)</p>

                <p>If you would like to continue with this swap, press Confirm.<br/>Else, close this window and edit the swap.</p>
            </div>

            <ConfirmButton onClick={onConfirm}>
                <p style={{fontSize: '1.2em', padding: '0', margin: '0.5rem'}}><b>Confirm</b></p>
            </ConfirmButton>
        </BasePopup>
    )
}
