import styles from './index.module.css'
import glassyStyles from '../../styles/neumorphic/glassy.module.css'
import shinyStyles from '../../styles/shine/shiny.module.css'

import BODALogo from '../../images/tokens/devel/BODAV2.png'
import BNBLogo from '../../images/tokens/devel/BNB.png'

import GlobalHeader from '../../components/inpage/headers/globalHeader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { SwapFooter } from '../../components/inpage/footers/swapFooter'
import ChainCarousel from '../../components/inpage/animated/chainCarousel'

import { motion } from 'framer-motion'
import HorizontalSection from '../../components/landing/horizontalSection'
import { NavLink } from 'react-router-dom'


export default function LandingPage() {
    return (
        <motion.div initial={{opacity: 0}} animate={{transition: {duration: 0.5, delay: 0.1}, opacity: 1}}>
            <motion.div initial={{backgroundColor: '#000'}}
                        animate={{transition: {duration: 1, delay: 0.75}, backgroundColor: '#001a04'}} 
                        className={styles.container}>
                <GlobalHeader />

                <div style={{height: '10rem'}} />

                <div className={`${styles.horizontalSection}`}>
                    <h1>Trade your favourite tokens in seconds</h1>
                    <NavLink to='/swap' className={`${glassyStyles.darkGlassy} ${styles.button}`}>
                        <div className={`${styles.shinyCover} ${shinyStyles.shiny}`}/>
                        Swap Now
                    </NavLink>
                </div>

                <div style={{height: '10rem'}} />

                <div className={`${styles.horizontalSection} ${styles.alignStart}`} style={{fontSize: '0.8em'}}>
                    <div className={`${styles.description} ${glassyStyles.darkGlassy} ${styles.padded}`}>
                        <h2>Statistics</h2>
                        <hr style={{width: '100%'}}/>
                        
                        <div className={styles.horizontalCentered}>
                            <label>Available</label>
                            <p>Yes</p>
                        </div>
                        <div className={styles.horizontalCentered}>
                            <label>Slippage</label>
                            <p>0%</p>
                        </div>
                        <div className={styles.horizontalCentered}>
                            <label>Token</label>
                            <p>BODAV2</p>
                        </div>
                        <div className={styles.horizontalCentered}>
                            <label>Referrals</label>
                            <p>300</p>
                        </div>
                        <div className={styles.horizontalCentered}>
                            <label>Total Voume</label>
                            <div className={`${styles.horizontalCentered} ${styles.justifyCenter} ${styles.minWidth} ${styles.noPadding}`}>
                                <img src={BNBLogo} />
                                <p>239</p>
                            </div>
                        </div>
                        <div className={styles.horizontalCentered}>
                            <label>Statistics #</label>
                            <p>2991</p>
                        </div>
                        <div className={styles.horizontalCentered}>
                            <label>API Status</label>
                            <p>LIVE</p>
                        </div>
                    </div>
                    <div className={`${styles.description} ${glassyStyles.darkGlassy} ${styles.padded}`}>
                        <h2>Overview</h2>
                        <hr style={{width: '100%'}}/>
                        
                        <div className={styles.horizontalCentered}>
                            <label>TTL</label>
                            <p>1D</p>
                        </div>
                        <div className={styles.horizontalCentered}>
                            <label>Dev Stage</label>
                            <p>Prototype</p>
                        </div>
                        <div className={styles.horizontalCentered}>
                            <label>Cool</label>
                            <p>Yes</p>
                        </div>
                    </div>
                </div>

                <div style={{height: '10rem'}}/>

                <HorizontalSection leftChild={
                    <div className={`${glassyStyles.darkGlassy} ${styles.tokenReadout}`}>
                        <img src={BNBLogo} />
                        <FontAwesomeIcon icon={solid("arrow-right")} />
                        <img src={BODALogo} />
                    </div>
                } rightChild={
                    <div className={styles.description}>
                        <h2>Trade BODAV2</h2>
                        <p>The best place to trade your BODA tokens</p>
                    </div>
                } reversed/>

                <div style={{height: '10rem'}}/>

                <HorizontalSection leftChild={
                    <div className={`${glassyStyles.darkGlassy} ${styles.tokenReadout}`}>
                        <ChainCarousel/>
                    </div>
                } rightChild={
                    <div className={styles.description}>
                        <h2>Multi-Chain Support</h2>
                        <p>Support for the leaders in the <br/>industry right from one place</p>
                    </div>
                }/>

                <div style={{height: '10rem'}}/>

                <HorizontalSection leftChild={
                    <div className={`${glassyStyles.darkGlassy} ${styles.tokenReadout}`}>
                        <p className={styles.zeroPercentText}>0%</p>
                    </div>
                } rightChild={
                    <div className={styles.description}>
                        <h2>Zero Slippage</h2>
                        <p>The first dex aggregator without slippage</p>
                    </div>
                } reversed/>

                <div style={{height: '10rem'}}/>


                <HorizontalSection leftChild={
                    <div className={`${glassyStyles.darkGlassy} ${styles.tokenReadout}`}>
                        <FontAwesomeIcon style={{fontSize: '4em'}} icon={solid("handshake")} />
                    </div>
                } rightChild={
                    <div className={styles.description}>
                        <h2>Refer A Friend</h2>
                        <p>Generate your personal link to<br/>recieve a kickback on their swaps</p>
                    </div>
                }/>

                <div style={{height: '10rem'}}/>

                <div style={{width: '100%', marginTop: 'auto'}}>
                    <SwapFooter/>
                </div>
            </motion.div>
        </motion.div>
    )
}
