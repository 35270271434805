import PCSToken from "../../types/token"
import TradingStrategyClient, { ChainSlug, ExchangeSlug, ExchangeType, TradingStrategyCandle, TradingStrategyVolumeCandle } from "../retrieval/tradingstrategy/TradingStrategyClient"


export type PCSChartData = {
    priceData: [{
        name: string,
        data: {
            x: number, 
            y: number[]
        }[]
    }]
    volumeData: TradingStrategyVolumeCandle[]
    baseToken: string
}

export const UpdatePCSChartData = async (dataClient: TradingStrategyClient, 
                                    tokens: {tokenA: PCSToken, tokenB: PCSToken},
                                    chainId: number, startDate: Date): Promise<PCSChartData> => {
    // Get internal pair ID (volatile, must be fetched each time)
    const pairAddress = chainId == 56 ? dataClient.getPairAddressPCS(tokens.tokenA, tokens.tokenB) : dataClient.getPairAddressUniswap(tokens.tokenA, tokens.tokenB, chainId)

    const pairData = await dataClient.fetchPairData((ExchangeSlug as any)[chainId], (ChainSlug as any)[chainId], pairAddress)

    const [priceData, volumeData] = await Promise.all([
        dataClient.getPriceData(pairData.pairId, '4h', startDate, (ExchangeType as any)[chainId]),
        (async () => { try { return await dataClient.getVolumeData(pairData.pairId, '4h', startDate, (ExchangeType as any)[chainId]) } catch (e) { console.warn("Charting volume for this pair was unavailable", e) } })()
    ])

    if ((Object.values(priceData.data)[0] as TradingStrategyCandle[]).length == 0) {
        throw new Error('Empty candles not supported')
    }

    const priceSeries = (Object.values(priceData.data)[0] as TradingStrategyCandle[]).map(x => {
        return {x: new Date(x.ts).getTime(), y: [x.o, x.h, x.l, x.c]}
    })

    return {
        priceData: [{ name: "Pair Price", data: priceSeries }],
        volumeData: volumeData !== undefined ? volumeData.data as TradingStrategyVolumeCandle[] : [],
        baseToken: pairData.baseTokenSymbol
    }
}

export default UpdatePCSChartData
