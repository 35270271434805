import PCSToken from "../types/token";
import useUniswap from "./useUniswap";
import { BigNumber } from "ethers";
import usePancakeSwap from "./usePancakeSwap";
import { useAccount } from "wagmi";


export default function useMultiSwap(inputToken: PCSToken, outputToken: PCSToken, inputAmount: BigNumber, outputAmount: BigNumber, referrer: `0x${string}` | undefined | null) {
    const { chain } = useAccount()
    
    //const pcs = usePancakeSwap(inputToken, outputToken, inputAmount, outputAmount, referrer)
    const uni = useUniswap(inputToken, outputToken, inputAmount, outputAmount, referrer)

    //return chain?.id ?? 56 === 56 ? pcs : uni
    return uni
}
