import styles from './networkReadout.module.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import SupportedNetworks, { SupportedNetworkSchematic } from '../../../web3/config/networks'
import { useAccount } from 'wagmi'


type NetworkDetails = {
    image?: string,
    name: string
}
function NetworkIcon(networkId: number): NetworkDetails {
    // Check the details against the database
    const netDetails = (SupportedNetworks as any)[networkId] as SupportedNetworkSchematic | null | undefined

    // If the network details exist and are thus supported, return that
    if (netDetails) {
        return {
            image: netDetails.iconUrl,
            name: netDetails.name
        } as NetworkDetails
    }

    // Otherwise return unsupported network details
    return { name: 'Currently Not Available' }
}


export const NetworkReadout = () => {
    const { chain } = useAccount()

    const chainDetails = NetworkIcon(chain?.id ?? 0)

    return (
        <div className={styles.container}>
            { chainDetails.image ? <div className={styles.imageContainer} /*style={{backgroundColor: chainDetails.image ? 'green' : 'red'}}*/>
                {   // Show the logo unless that isn't available in which case display the question mark (unknown) icon
                    chainDetails.image ? 
                        <img src={chainDetails.image} alt='' /> : 
                        <FontAwesomeIcon icon={solid("circle-question")} />
                }
            </div> : <div/> }
            <p>{chainDetails.name}</p>
        </div>
    )
}
