import { ReactNode } from 'react'
import styles from './confirmButton.module.css'


type ConfirmButtonProps = {
    onClick: React.MouseEventHandler<HTMLButtonElement>
    children?: ReactNode
    disabled?: boolean
}

export const ConfirmButton = (props: ConfirmButtonProps) => {
    return (
        <button className={styles.button} onClick={props.onClick} disabled={props.disabled}>
            {props.children}
        </button>
    )
}


export default ConfirmButton
