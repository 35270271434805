import React from "react";
import styles from "./splitView.module.css";

type SplitViewProps = {
    sides: React.ReactNode[]

    flip: boolean
}
export const SwapSplitView = (props: SplitViewProps) => {
    return (
        <div className={styles.container}>
            <div className={`${styles.splitView} ${props.flip ? styles.arrangeLeft : styles.arrangeRight}`}>
                {props.sides.map((x, idx) => <div key={idx}>{x}</div>)}
            </div>
        </div>
    )
}

export default SwapSplitView;
