import styles from './horizontalSection.module.css'
import { ReactNode } from 'react'


type HorizontalSectionProps = {
    leftChild: ReactNode
    rightChild: ReactNode

    reversed?: boolean
}

export default function HorizontalSection(props: HorizontalSectionProps) {
    return (
        <div className={`${styles.horizontalSection} ${props.reversed ? styles.wrapReverse : ''}`}>
            {props.reversed ? props.rightChild : props.leftChild}
            {props.reversed ? props.leftChild : props.rightChild}
        </div>
    )
}
