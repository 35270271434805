
import ETHLogo from '../../images/tokens/devel/ETH.png'
import USDCLogo from '../../images/tokens/devel/USDC.svg'
import MATICLogo from '../../images/tokens/devel/MATIC.svg'
import USDTLogo from '../../images/tokens/devel/USDT.svg'


export const PolygonTokens = [
    {
        name: 'Polygon',
        symbol: 'MATIC',
        decimals: 18,
        address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
        logoUrl: MATICLogo,
        isNativeToken: true
    },
    {
        name: 'USD Coin',
        symbol: 'USDC',
        decimals: 6,
        address: '0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359',
        logoUrl: USDCLogo
    },
    {
        name: 'Wrapped Ethereum',
        symbol: 'WETH',
        decimals: 18,
        address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
        logoUrl: ETHLogo
    },
    {
        name: 'Tether USD',
        symbol: 'USDT',
        decimals: 6,
        address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
        logoUrl: USDTLogo
    }
]
