import styles from './chartPanel.module.css'
import ReactApexChart from "react-apexcharts"
import ChartDisplay from "./chart/chartDisplay"
import PCSToken from "../../../types/token"
import { ApexOptions } from "apexcharts"
import { ChartPriceReadout } from "./chart/chartPriceReadout"
import ExampleData from "../../../data/devel/example/exampleData"
import useSwap from '../../../hooks/context/swapContext'
import usePopups from '../../../hooks/usePopups'


export const ChartPanel = () => {
    const popups = usePopups()

    const { 
        activeCurrency: currency,
        chartSeries: series,
        chartAvailable,
        tokens,
        baseToken
    } = useSwap()

    const handleCurrencySelectClicked = () => popups.setPopup('CurrencySelect')
    
    // If charting is unavailable for any reason, likely because the pair isn't tracked
    if (!chartAvailable) {
        return (
            <div className={styles.container}>
                <div className={styles.unavailable}>
                    <h1>Chart Unavailable</h1>
                    <p className={styles.unavailableDescription}>There is currently no trading data on this pair, check back later</p>
                </div>

                <ChartDisplay timeFrame="N/A" tokenA={tokens.tokenA} tokenB={tokens.tokenB} />
                <ChartPriceReadout tickerClicked={() => {}} pricePer="Unknown" tickerNames={`${tokens.tokenB.symbol}/${tokens.tokenA.symbol}`} priceDifferencePercentage={0}/>

                <PriceChart series={ExampleData satisfies ApexAxisChartSeries}/>
            </div>
        )
    }

    const latestPrice = ((series[0].data[series[0].data.length - 1] as any).y[3] as number)
    const firstPrice = ((series[0].data[0] as any).y[2] as number)

    const percentageChange = ((latestPrice - firstPrice) / ((latestPrice + firstPrice) / 2)) * 100

    return (
        <div className={styles.container}>
            <ChartDisplay timeFrame="1 Year" tokenA={tokens.tokenA} tokenB={tokens.tokenB} />
            <ChartPriceReadout tickerClicked={handleCurrencySelectClicked} pricePer={Number.parseFloat((latestPrice * currency[1]).toPrecision(8)).toLocaleString()} tickerNames={`${currency[0]}`} priceDifferencePercentage={Number.parseFloat(percentageChange.toPrecision(4))}/>

            <PriceChart series={series}/>
        </div>
    )
}


const PriceChart = (props: {series: ApexAxisChartSeries}) => {

    const options: ApexOptions = {
        colors:['#31d0aa'],
        chart: {
            zoom: {
                enabled: false
            }
        },
        markers: {
            colors: '#FFF'
        },
        yaxis: {
            labels: {
                style: {
                    colors: '#FFF'
                },
                //formatter(val, opts) {
                 //   return val.toPrecision(2)
                //}
            },
            show: false
        },
        xaxis: {
            type: 'datetime',
            labels: {
                style: {
                    colors: '#FFF'
                },
            }
        },
        tooltip: {
            x: {
                format: 'dd MMM yyyy'
            }
        },
        stroke: {
            width: [3, 1]
        }
    };
  
    return (
        <ReactApexChart
          type="candlestick"
          options={options}
          series={props.series}
          height={350}
        />
    );
};
