import React, { useMemo, useState } from 'react'
import PCSToken from '../../../types/token'
import SwapInputField from '../ui/input/inputFields/swapInputField'
import BasePopup from './basePopup'
import styles from './tokenSelectionPopup.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { ethers } from 'ethers'
import useCustomTokenDetails from '../../../hooks/useCustomTokenDetails'
import ConfirmButton from '../ui/buttons/confirmButton'
import usePopups from '../../../hooks/usePopups'
import { useAccount } from 'wagmi'
import useSwap from '../../../hooks/context/swapContext'

type TokenSelectionPopupProps = {
    tokens: PCSToken[]
    selectedTokens: { tokenA: PCSToken, tokenB: PCSToken }

    onTokenSelected: (token: PCSToken) => void

    inputTokenLastSelected: boolean
}



const TokenSearchField = (props: { searchField: string, searchFieldUpdate: (value: string) => void }) => {
    return (
        <div className={styles.inputContainer}>
            <SwapInputField placeholder='Search Token or Contract Address' value={props.searchField} onUpdate={props.searchFieldUpdate} label='Search or Add Token' />
        </div>
    )
}

const tokensAreEqual = (tokenA: PCSToken, tokenB: PCSToken): boolean => tokenA.address.toLowerCase() === tokenB.address.toLowerCase()
const TokenListing = (props: { selectedTokens: { tokenA: PCSToken, tokenB: PCSToken }, token: PCSToken, onClick: (token: PCSToken) => void }) => {
    const tokenIsSelected = tokensAreEqual(props.token, props.selectedTokens.tokenA) || tokensAreEqual(props.token, props.selectedTokens.tokenB)
    
    return (
        <button disabled={tokenIsSelected} onClick={() => props.onClick(props.token)} className={styles.tokenListing}>
            <img className={styles.tokenIcon} src={props.token.logoUrl} alt={props.token.name} />
            <div className={styles.tokenDetails}>
                <h4>{props.token.symbol}</h4>
                <p>{props.token.name}</p>
            </div>
            { tokenIsSelected && <FontAwesomeIcon icon={solid('check')} style={{marginLeft: 'auto', marginRight: '1em'}} /> }
        </button>
    )
}


export const TokenSelectionPopup = () => {
    const popups = usePopups()

    const {
        tokens: selectedTokens,
        tokenASelected: inputTokenLastSelected,
        tokenList: tokens,

        handleTokenSelected: onTokenSelected
    } = useSwap()

    const [searchField, setSearchField] = useState('')
    const onSearchFieldUpdate = (value: string) => { setSearchField(value) }
    const { fetching, tokenDetails } = useCustomTokenDetails(searchField)
    const [confirmed, setConfirmed] = useState(false)
    const { chain } = useAccount()

    const [reloadCustomTokensTrigger, setReloadCustomTokensTrigger] = useState(false)
    const localCustomTokens = useMemo(() => {return JSON.parse(window.localStorage.getItem('customTokens')!) as (PCSToken & {chainId: number})[]}, [reloadCustomTokensTrigger])

    const handleAddCustomToken = () => {
        const currentSavedTokens = JSON.parse(window.localStorage.getItem('customTokens')!)
        window.localStorage.setItem('customTokens', JSON.stringify([...currentSavedTokens, tokenDetails]))
        
        setReloadCustomTokensTrigger(x => !x)
        onTokenSelected(tokenDetails)
    
        setSearchField("")
    }

    const tokenListings = [] as React.ReactNode[]

    tokens.concat(...(localCustomTokens.filter(x => x.chainId == chain?.id ?? 56))).forEach(token => {
        if (token.name.toLowerCase().startsWith(searchField.toLowerCase()) || token.address.toLowerCase().startsWith(searchField.toLowerCase()) || token.symbol.toLowerCase().startsWith(searchField.toLowerCase())) {
            tokenListings.push(
                <TokenListing selectedTokens={selectedTokens} key={token.address} token={token} onClick={onTokenSelected} />
            )
        }
    })

    if (tokenListings.length === 0) {
        if (ethers.utils.isAddress(searchField)) {
            tokenListings.push(
                <div>
                    <h4>Token Details</h4>
                    <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                        <p><b>Name: </b></p>
                        <div style={{margin: 'auto'}}/>
                        <p style={{textAlign: 'center'}}>{tokenDetails.name}</p>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                        <p><b>Decimals: </b></p>
                        <div style={{margin: 'auto'}}/>
                        <p style={{textAlign: 'center'}}>{tokenDetails.decimals}</p>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', width: '100%'}}>
                        <p><b>Symbol: </b></p>
                        <div style={{margin: 'auto'}}/>
                        <p style={{textAlign: 'center'}}>{tokenDetails.symbol}</p>
                    </div>
                </div>
            )

            tokenListings.push(
                <div>
                    <p style={{maxWidth: '30em'}}><span style={{color: 'red', fontSize: '1.1em'}}>Warning: </span>Anyone can make a token, including fake versions of existing tokens. You may not be able to sell back a fake token.<br/>Proceed with caution and make sure you trust the address you are importing.</p>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                        <input type='checkbox' id='tokenConfirmationCheckbox' onClick={() => setConfirmed(x => !x)} checked={confirmed} />
                        <label style={{marginLeft: '1em'}}><p style={{color: 'orangered', userSelect: 'none'}} onClick={() => setConfirmed(x => !x)}>I understand the risks involved</p></label>
                    </div>
                </div>
            )

            tokenListings.push(
                <ConfirmButton disabled={!confirmed} onClick={handleAddCustomToken}>
                    <h4>Add Token</h4>
                </ConfirmButton>
            )
        }
        else {
            tokenListings.push(
                <p style={{textAlign: 'center'}}>No tokens were found with your search query.<span style={{fontSize: '0.9em'}}><br/>Try searching for another token or paste in the<br/>contract address to import a new token</span></p>
            )
        }
    }

    return (
        <BasePopup showing={popups.activePopup === 'TokenSelection'} title={'Select ' + (inputTokenLastSelected ? 'Input' : 'Output') + ' Token'} handleClose={popups.close} minWidth='21em'>
            <TokenSearchField searchField={searchField} searchFieldUpdate={onSearchFieldUpdate} />
            
            <div className={styles.content}>
                {tokenListings}
            </div>
        </BasePopup>
    )
}

export default TokenSelectionPopup
