import styles from './userProfile.module.css'

import glassyStyles from '../../../../styles/neumorphic/glassy.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import usePopups from '../../../../hooks/usePopups'
import useBodaSwapApi from '../../../../hooks/useBodaSwapApi'
import { Link } from 'react-router-dom'


export default function UserProfile() {
    const popups = usePopups()
    const { auth } = useBodaSwapApi()

    const onClick = () => {
        if (auth.jwt)
            return
        
        popups.setPopup('SignIn')
    }

    const loginButton = (
        <button 
        onClick={onClick}
        className={`${glassyStyles.darkGlassy} ${styles.button}`}>
            <p style={{margin: '0', padding: '0'}}>
                { auth.jwt ? 'Account' : 'Log In' }
            </p>
            <FontAwesomeIcon icon={solid('user-circle')} style={{color: auth.jwt ? 'green' : 'white'}} />
        </button>
    )

    if (!auth.jwt) 
        return loginButton

    return (
        <Link to='/account'>
            {loginButton}
        </Link>
    )
}
