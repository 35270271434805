import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './closeButton.module.css'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

type CloseButtonProps = {
    onClick?: React.MouseEventHandler<HTMLButtonElement>
}

export const CloseButton = (props: CloseButtonProps) => {
    return (
        <button onClick={props.onClick} className={styles.button}>
            <FontAwesomeIcon icon={solid('x')} />
        </button>
    )
}

export default CloseButton
