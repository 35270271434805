import { validateAndParseAddress } from "@pancakeswap/sdk"
import { Contract, ethers } from "ethers"
import { erc20Abi } from "viem"
import { useEffect, useState } from "react"
import { useAccount, useReadContract, useReadContracts } from "wagmi"
import { ExternalProvider, JsonRpcFetchFunc } from "@ethersproject/providers"


type ContractInfo = {
    symbol: string
    name: string
    decimals: number
}
async function tryGetTokenDetails(tokenAddress: string, contractInfo: ContractInfo, chainId?: number) {
    
    try {        
        const address = validateAndParseAddress(tokenAddress)

        let icon = 'unknown.png' // TODO Maybe change to an SVG?
        
        try {
            const geckoResponse = await fetch('https://api.coingecko.com/api/v3/coins/binance-smart-chain/contract/' + address)
            icon = JSON.parse((await geckoResponse.text())).image.large
        }
        catch { }

        return({
            name: contractInfo.name,
            symbol: contractInfo.symbol,
            decimals: contractInfo.decimals,
            address: address,
            logoUrl: icon,
            chainId: chainId ?? 56
        })
    }
    catch (ex) {
        console.warn("Couldn't load custom token - the address is probably invalid\n\n", ex)

        return({
            name: 'Invalid Token',
            symbol: '-',
            decimals: 0,
            address: '',
            logoUrl: '',
            chainId: chainId ?? 56
        })
    }
}

let latestFetchId = 0
export default function useCustomTokenDetails(tokenAddress: string) {
    
    const { connector, chain } = useAccount()

    const [fetching, setFetching] = useState(false)
    const [tokenDetails, setTokenDetails] = useState({
        name: 'Invalid Token',
        symbol: '-',
        decimals: 0,
        address: '',
        logoUrl: '',
        chainId: chain?.id ?? 56
    })

    const baseContract = {
        address: tokenAddress as `0x${string}`,
        abi: erc20Abi
    } as const

    const { refetch } = useReadContracts({
        contracts: [
            {
                ...baseContract,
                functionName: 'symbol'
            },
            {
                ...baseContract,
                functionName: 'name'
            },
            {
                ...baseContract,
                functionName: 'decimals'
            }
        ]
    })


    useEffect(() => {
        async function updateDetails() {
            latestFetchId += 1

            const thisFetchId = latestFetchId

            setFetching(true)

            try {
                const contractData = (await refetch()).data
                
                if (!contractData || !contractData[0].result || !contractData[1].result || !contractData[2].result)
                    throw new Error('Contract is not valid ERC20')
    
                const contractInfo: ContractInfo = {
                    symbol: contractData[0].result,
                    name: contractData[1].result,
                    decimals: contractData[2].result
                }

                const newDetails = await tryGetTokenDetails(tokenAddress, contractInfo, chain?.id)
    
                // If a new fetch has been initiated since the last fetch, ignore
                // This should be early return
                if (latestFetchId == thisFetchId) {
                    setFetching(false)
                    setTokenDetails(newDetails)
                }
            }
            catch { }
        }
        updateDetails()
    }, [tokenAddress])

    return {
        tokenDetails,
        fetching
    }
}
