import styles from './swapFooter.module.css'

import DiscordLogo from '../../../images/icons/636e0a6ca814282eca7172c6_icon_clyde_white_RGB.svg'
import XLogo from '../../../images/icons/logo-white.png'
import FacebookLogo from '../../../images/icons/Facebook_Logo_Secondary.png'
import InstagramLogo from '../../../images/icons/Instagram_Glyph_White.svg'
import YoutubeLogo from '../../../images/icons/youtube.svg'


export const SwapFooter = () => {
    return (
        <footer className={styles.footer}>
            <section>
                <h4>Ecosystem</h4>

                <a href='https://www.bodatoken.app/'>Home</a>
                <a href='https://www.bodatoken.app/dashboard'>Dashboard</a>
                <a href='https://boda-token.gitbook.io/boda-docs/boda-token/what-is-boda-token'>Docs</a>
                <a href='https://boda-token.gitbook.io/boda-docs/boda-token/whitepaper'>Whitepaper</a>
            </section>

            <section>
                <h4>Support</h4>

                <a href='https://boda-token.gitbook.io/boda-docs/boda-token/what-is-boda-token'>Guide</a>
            </section>

            <section className={styles.socials}>
                <h4>Socials</h4>

                <div className={styles.socialsContainer}>
                    <a href="https://discord.gg/XMF4UctSnv"><img src={DiscordLogo}></img></a>
                    <a href="https://twitter.com/BodaToken"><img src={XLogo}></img></a>
                    <a href="https://www.facebook.com/BodaToken"><img src={FacebookLogo}></img></a>
                    <a href="https://www.instagram.com/bodatoken/"><img src={InstagramLogo}></img></a>
                    <a href="https://www.youtube.com/channel/UCZA6agzb8YjBvPj-T8EVijA"><img src={YoutubeLogo} style={{filter: 'invert()'}}></img></a>
                </div>
            </section>

            <section>
                <h4>Support</h4>

                <div className={styles.socialsContainer}>
                    <a href='/tos'>Terms of Service</a>
                </div>
            </section>
        </footer>
    )
}
