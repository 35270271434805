import { Time } from "lightweight-charts"


export const ExampleData = 
[
  {
    data: [
      {x: 1538856000000, y: [6593.34, 6600, 6582.63, 6600]}, 
      {x: 1538856900000, y: [6595.16, 6604.76, 6590.73, 6593.86]}
    ]
  }
]

export const ExampleVolumeData = [] as {time: Time, value: number, color: string}[]

export default ExampleData
