import styles from './percentageInputSlider.module.css'

import { useRef, useState } from 'react'


type PercentageInputSliderProps = {
    onPercentageSet: (newValue: number) => void
}

export default function PercentageInputSlider(props: PercentageInputSliderProps) {
    const containerRef = useRef(null as HTMLDivElement | null)

    const [value, setValue] = useState('0')

    return (
        <>
            <div className={styles.container}>
                <label>{value}%</label>
                <input
                    className={styles.slider}
                    type='range' 
                    min='0' 
                    max='100' 
                    value={value} 
                    onChange={e => setValue(e.target.value)} 
                    onMouseUp={(e) => props.onPercentageSet(Number.parseInt(e.currentTarget.value))}
                    />
            </div>
        </>
    )
}
