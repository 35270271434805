import styles from './index.module.css'

export const PoolsPage = () => {
    return (
        <div>
            
        </div>
    )
}

export default PoolsPage
