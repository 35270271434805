import styles from './swapSubHeading.module.css'

type SwapSubheadingProps = {
    text: string
}


export const SwapSubheading = (props: SwapSubheadingProps) => {
    return (
        <div className={styles.container}>
            <h4>{props.text}</h4>
        </div>
    )
}

export default SwapSubheading
