import styles from './tokenButton.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import PCSToken from '../../../../types/token';


type TokenButtonProps = {
    token: PCSToken
    onTokenClicked: (token: PCSToken) => void
}

export const TokenButton = (props: TokenButtonProps) => {
    return (
        <div className={styles.container}>
            <button onClick={() => props.onTokenClicked(props.token)} className={styles.button}>
                <img src={props.token.logoUrl} className={styles.tokenImage} alt=''/>
                <p className={styles.tokenSymbol}><b>{props.token.symbol}</b></p>
                <FontAwesomeIcon icon={solid('caret-down')} className={styles.caret} />
            </button>
        </div>
    )
}

export default TokenButton
