import './index.css'

import React from 'react'
import ReactDOM from 'react-dom/client'

import App from './App'

import { WagmiProvider } from 'wagmi'
import { createWeb3Modal } from '@web3modal/wagmi/react'
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { arbitrum, avalanche, base, bsc, cronos, mainnet, polygon, pulsechain } from 'wagmi/chains'



const queryClient = new QueryClient()

const chains = [arbitrum, avalanche, base, bsc, cronos, mainnet, polygon, pulsechain]
const projectId = '50daa2eb74d27c8360a5af851ce5f89c'
const metadata = {
  name: 'BodaSwap',
  description: 'BodaSwap Indev',
  url: 'https://bodaswap.com', // origin must match your domain & subdomain
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}


const config = defaultWagmiConfig({
  chains, // required
  projectId, // required
  metadata, // required
  enableWalletConnect: true, // Optional - true by default
  enableInjected: true, // Optional - true by default
  enableEIP6963: true, // Optional - true by default
  enableCoinbase: true, // Optional - true by default
})


createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true // Optional - defaults to your Cloud configuration
})



Number.prototype.countDecimals = function () {
  if(Math.floor(this.valueOf()) === this.valueOf()) return 0;
  return this.toString().split(".")[1].length || 0; 
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <App/>
      </QueryClientProvider>
    </WagmiProvider>
  </React.StrictMode>
)
