import { Outlet } from "react-router"
import { SignInPopup } from "../components/bodaswap/popups/user/login/loginPopup"
import { SignUpPopup } from "../components/bodaswap/popups/user/login/signUpPopup"
import { ConfirmEmailPopup } from "../components/bodaswap/popups/user/login/emailPopup"


export default function TopLevelLayout() {
    return (
        <>
            <SignInPopup />
            <SignUpPopup />
            <ConfirmEmailPopup />

            <Outlet />
        </>
    )
}
