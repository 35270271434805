import PCSToken from "../../types/token";

import ETHLogo from '../../images/tokens/devel/ETH.png'
import USDCLogo from '../../images/tokens/devel/USDC.svg'
import DAILogo from '../../images/tokens/devel/DAI.svg'


export const BaseTokens = [
    {
        name: 'Ethereum',
        symbol: 'ETH',
        decimals: 18,
        address: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
        logoUrl: ETHLogo,
        isNativeToken: true
    },
    {
        name: 'USD Coin',
        symbol: 'USDC',
        decimals: 6,
        address: '0x833589fcd6edb6e08f4c7c32d4f71b54bda02913',
        logoUrl: USDCLogo
    },
    {
        name: 'Dai',
        symbol: 'DAI',
        decimals: 18,
        address: '0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb',
        logoUrl: DAILogo
    }
] as PCSToken[]
