import { useEffect, useState } from "react";
import { AnimatePresence, motion } from 'framer-motion'

import BNBLogo from '../../../images/tokens/devel/BNB.png'
import MATICLogo from '../../../images/tokens/devel/MATIC.svg'
import ETHLogo from '../../../images/tokens/devel/ETH.png'
import ARBLogo from '../../../images/chains/ARB.svg'


export default function ChainCarousel() {
    const [caroselValue, setCaroselValue] = useState(0)

    useEffect(() => {
        const interval = setInterval(() => setCaroselValue(x => ++x), 2000);

        return () => clearInterval(interval)
    }, [])

    const exitAnim = {
        opacity: 0,
        transform: 'translateY(-60%)'
    }
    const initialAnim = {
        opacity: 0,
        transform: 'translateY(60%)'
    }
    const anim = {
        transform: 'translateY(0%)',
        opacity: 1
    }

    return (
        <AnimatePresence mode='popLayout'>
        {[
            <motion.img style={{fontSize: '1.5em', borderRadius: '0'}} animate={anim} initial={initialAnim} exit={exitAnim} src={MATICLogo} key='matic'/>,
            <motion.img style={{fontSize: '1.5em', borderRadius: '0'}} animate={anim} initial={initialAnim} exit={exitAnim} src={BNBLogo} key='bnb'/>,
            <motion.img style={{fontSize: '1.5em', borderRadius: '0'}} animate={anim} initial={initialAnim} exit={exitAnim} src={ETHLogo} key='eth'/>,
            <motion.img style={{fontSize: '1.5em', borderRadius: '0'}} animate={anim} initial={initialAnim} exit={exitAnim} src={ARBLogo} key='arb'/>
        ][caroselValue % 4]} {/* Will select one every interval then repeat */}
    </AnimatePresence>
    )
}
