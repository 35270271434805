
import ETHLogo from '../../images/tokens/devel/ETH.png'
import USDCLogo from '../../images/tokens/devel/USDC.svg'
import PLSLogo from '../../images/tokens/devel/PLS.png'


export const PulseTokens = [
    {
        name: 'Ethereum',
        symbol: 'ETH',
        decimals: 18,
        address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
        logoUrl: ETHLogo,
        isNativeToken: true
    },
    {
        name: 'USD Coin',
        symbol: 'USDC',
        decimals: 6,
        address: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
        logoUrl: USDCLogo
    },
    {
        name: 'Pulse',
        symbol: 'PLS',
        decimals: 6,
        address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
        logoUrl: PLSLogo
    }
]
