import { Link } from 'react-router-dom'
import { SubMenuNavLink } from '../globalHeader'
import styles from './index.module.css'

import { Variants, motion } from 'framer-motion'
import { useState } from 'react'


type SubNavMenuProps = {
    open: boolean
    links: Readonly<SubMenuNavLink[]>
}

export default function SubNavMenu(props: SubNavMenuProps) {
    const [overrideOpen, setOverrideOpen] = useState(false)

    const localOpen = overrideOpen || props.open

    const varients = {
        closed: {
            opacity: 0
        },
        open: {
            opacity: 1
        }
    } satisfies Variants

    return (
        <motion.div 
            className={`${styles.container} ${styles.arrow_box}`} 
            initial='closed' 
            animate={localOpen ? 'open' : 'closed'} 
            variants={varients}
            transition={{
            }}
            style={{
                pointerEvents: localOpen ? 'auto' : 'none'
            }}
            onMouseEnter={() => {
                if (props.open) {
                    setOverrideOpen(true)
                }
            }} 
            onMouseLeave={() => setOverrideOpen(false)}
        >
            {
                props.links.map((x, idx) => (
                    <Link key={idx} to={x.path} className={styles.subLinkContainer}>
                        {x.icon}
                        <div className={styles.subLinkTextContainer}>
                            <p>{x.name}</p>
                            <p>{x.description}</p>
                        </div>
                    </Link>
                ))
            }
        </motion.div>
    )
}
