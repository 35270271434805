import styles from './swapPanel.module.css';

import PCSToken from '../../../types/token';
import SwitchButton from '../ui/buttons/switchButton';
import TradeReadout from '../readouts/tradeReadout';
import SwapInputField from "../ui/input/inputFields/swapInputField";
import HorizontalSeperator from "../ui/seperators/horizontalSeperator";
import { SwapHeader } from "../ui/headings/swapHeader";
import { ConfirmButton } from '../ui/buttons/confirmButton';
import { SwapSubheading } from "../ui/headings/swapSubheading";
import { BigNumber, ethers } from 'ethers';
import { TokenSelectionPanel } from "./tokenSelectionPanel";
import SwapButtons, { SwapSettingsButtonStatuses } from "../ui/buttons/swapButtons";
import PercentageInputSlider from '../ui/sliders/percentageInputSlider';
import { useAccount } from 'wagmi';
import useSwap from '../../../hooks/context/swapContext';
import usePopups from '../../../hooks/usePopups';


/**
 * Returns a string that is formatted to a presision of 4 decimals, truncated
 */
function formatBalanceReadout(balance: BigNumber, decimals: number): string {
    return (+ethers.utils.formatUnits(balance, decimals)).toFixed(4)
}

export const SwapPanel = () => {
    const popups = usePopups()

    const {
        inputValue,
        outputValue,
        handleInputValueChanged,
        handleOutputValueChanged,

        tokens,

        lastPrice,
        lastProtocol,
        showChart,

        setInvertPanel,
        setShowChart,
        handleTokenSelected,
        handleTokenClicked,
        handleSwapTokens,
        handleSettingsClicked,
        handleRefreshClicked,
        onPercentageSet,

        swapLoading,

        tokenABalance: inputBalance,
        tokenBBalance: outputBalance,
        tokenARawLoading,
        tokenAIsRefetching,
        tokenBRawLoading,
        tokenBIsRefetching

    } = useSwap()

    const setters = {
        setSwapSides: setInvertPanel,
        setViewChart: setShowChart,
        handleTokenSelected,
        handleTokenClicked,
        handleSwapTokens,
        handleSettingsClicked,
        handleRefreshClicked
    }

    const handleSpendButtons = {
        twentyFivePercentClicked: () => { handleInputValueChanged(ethers.utils.formatUnits(inputBalance.div(4).toString(), tokens.tokenA.decimals)) },
        fiftyPercentClicked: () => { handleInputValueChanged(ethers.utils.formatUnits(inputBalance.div(2).toString(), tokens.tokenA.decimals)) },
        maxButtonClicked: () => { handleInputValueChanged(ethers.utils.formatUnits(inputBalance.toString(), tokens.tokenA.decimals)) }
    }


    const { status } = useAccount()

    const swapButtonText = status === 'connected' ? (swapLoading ? 'Routing Swap...' : 'Review Swap') : 'Connect Wallet'

    return (
        <div className={styles.container}>
            <SwapHeader text="BODA Swap" />
            <SwapSubheading text="The First DEX Aggregator With Zero Slippage" />

            <SwapButtons setters={setters} disableButtons={{disableSwapSides: !showChart}}/>

            <HorizontalSeperator/>


            {/* Input Token */}
            <TokenSelectionPanel balanceReadout={(tokenARawLoading || tokenAIsRefetching) ? 'Fetching...' : formatBalanceReadout(inputBalance, tokens.tokenA.decimals)} token={tokens.tokenA} onClicked={() => {setters.handleTokenClicked(true)}}/>
            <SwapInputField value={ethers.utils.formatUnits(inputValue, tokens.tokenA.decimals)} onUpdate={handleInputValueChanged} label='Input Amount' />

            <PercentageInputSlider onPercentageSet={onPercentageSet}/>
            {/* <SpendButtons handleSpendButtons={handleSpendButtons} /> */}
            <SwitchButton onClick={setters.handleSwapTokens} />

            {/* Output token */}
            <TokenSelectionPanel balanceReadout={tokenBRawLoading || tokenBIsRefetching ? 'Fetching...' : formatBalanceReadout(outputBalance, tokens.tokenB.decimals)} token={tokens.tokenB} onClicked={() => {setters.handleTokenClicked(false)}} />
            <SwapInputField disabled={swapLoading} value={ethers.utils.formatUnits(outputValue, tokens.tokenB.decimals)} onUpdate={handleOutputValueChanged} label='Output Amount' />


            <div style={{height: '2em'}} />

            <TradeReadout liquidityProtocolReadoutContent={lastProtocol ?? '-'} priceReadoutContent={`${Number.isNaN(lastPrice) ? '-' : lastPrice} ${tokens.tokenB.symbol} per ${tokens.tokenA.symbol}`} />

            <div style={{height: '2em'}} />

            <ConfirmButton onClick={() => popups.setPopup('ConfirmSwap')} disabled={swapLoading || status !== 'connected'}>
                <p style={{fontSize: '1.2em', padding: '0', margin: '0.5rem'}}><b style={{color: swapLoading ? '#ccc' : 'white'}}>{swapButtonText}</b></p>
            </ConfirmButton>
        </div>
    )
}
