import BodaSwap from "../../components/bodaswap/bodaSwap.tsx";


export const SwapPage = () => {
    return (
        <BodaSwap />
    )
}

export default SwapPage
