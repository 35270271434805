import styles from './swapFailedPopup.module.css'

import BasePopup from '../basePopup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import EverRevokeLogo from '../../../../images/tokens/devel/EverRevoke.svg'
import usePopups from '../../../../hooks/usePopups'
import { useAccount } from 'wagmi'


type SwapSucceededPopupProps = {
}
export const SwapSucceededPopup = (props: SwapSucceededPopupProps) => {
    const popups = usePopups()

    const { chainId } = useAccount()

    return (
        <BasePopup showing={popups.activePopup === 'SwapSuccess'} idealWidth='20rem' minWidth='13rem' handleClose={popups.close} title='Success'>
            <div className={styles.textContainer}>
                <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                    <FontAwesomeIcon color='white' className={styles.icon} icon={regular('circle-check')} />
                </div>

                <div style={{display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                    <h2 style={{marginBottom: '0.2em'}}>Swap Succeeded</h2>
                    <p style={{margin: '0'}}>Your swap is finished!</p>
                </div>

                {
                    chainId && chainId === 56 && (
                        <a rel="noopener noreferrer" target='_blank' href='https://everrise.com/everrevoke/' style={{textDecoration: 'none', boxSizing: 'border-box', width: '100%', marginTop: '2em', display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor: '#111', padding: '1em', borderRadius: '15px', color: 'white'}}>
                            <img style={{width: '25%'}} src={EverRevokeLogo}/>
                            <p style={{width: '90%', marginLeft: '1em', textAlign: 'left'}}>
                                <b>Done using the swap?</b><br/>To protect your wallet we recommend using EverRevoke to remove approval from any DEX. <span style={{color: 'lightgreen'}}>Click here</span> to access EverRevoke
                            </p>
                        </a>
                    )
                }

            </div>
        </BasePopup>
    )
}
