import { useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router"
import { ethers } from "ethers"


export default function useReferral() {
    const { search } = useLocation()
    
    const params = useMemo(() => new URLSearchParams(search), [search])
    const [referrer, setReferrer] = useState(undefined as `0x${string}` | undefined | null)

    useEffect(() => {
        const potentialReferrer = params.get('referrer')

        if (!potentialReferrer) {
            return
        }

        try {
            ethers.utils.getAddress(potentialReferrer)

            setReferrer(potentialReferrer as `0x${string}`) // We know its a valid type because ethers did not throw an argument exception
        }
        catch {
            console.error("Invalid referrer, using none.")
        }

    }, [params])

    return referrer
}
