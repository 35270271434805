import PCSToken from "../../types/token";

import ARBLogo from '../../images/chains/ARB.svg'
import ETHLogo from '../../images/tokens/devel/ETH.png'
import USDTLogo from '../../images/tokens/devel/USDT.svg'


export const ArbitrumOneTokens = [
    {
        name: 'Ethereum',
        symbol: 'ETH',
        decimals: 18,
        address: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
        logoUrl: ETHLogo,
        isNativeToken: true
    },
    {
        name: 'Arbitrum',
        symbol: 'ARB',
        decimals: 18,
        address: '0x912CE59144191C1204E64559FE8253a0e49E6548',
        logoUrl: ARBLogo
    },
    {
        name: 'Tether USD',
        symbol: 'USDT',
        decimals: 6,
        address: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
        logoUrl: USDTLogo
    }
] as PCSToken[]
