import styles from './swapFailedPopup.module.css'

import BasePopup from '../basePopup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import usePopups from '../../../../hooks/usePopups'


type SwapFailedPopupProps = {
}
export const SwapFailedPopup = (props: SwapFailedPopupProps) => {
    const popups = usePopups()

    return (
        <BasePopup showing={popups.activePopup === 'SwapFailed'} idealWidth='20rem' minWidth='13rem' handleClose={popups.close} title='Swap Failed'>
            <div className={styles.textContainer}>
                <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                    <FontAwesomeIcon color='white' className={styles.icon} icon={regular('circle-xmark')} />
                </div>

                <h2>Swap Failed</h2>

                <p>Looks like the swap failed. Please try setting a higher slippage. If the transaction still fails, check that you have enough funds and that there is enough liquidity for your transaction.</p>

                <b>For further troubleshooting steps, <a style={{color: 'green', textDecoration: 'none'}} href='https://boda-token.gitbook.io/boda-docs/bodaswap-guides/troubleshooting-errors'>click here</a></b>
            </div>
        </BasePopup>
    )
}
