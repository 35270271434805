import styles from './swapSettingsButton.module.css'
import { icon, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MouseEventHandler, useState } from 'react'

export enum SwapSettingsButtonContent {
    settings,
    swapSides,
    toggleChart,
    refresh
}

export type SwapSettingsButtonProps = {
    content: SwapSettingsButtonContent,
    onClick?: MouseEventHandler<HTMLButtonElement>,
    disabled?: boolean
}


function getIconFromButtonContent(options: {content: SwapSettingsButtonContent, color: string, pointer: boolean}): JSX.Element {
    switch (options.content) {
        case SwapSettingsButtonContent.settings:
            return <FontAwesomeIcon icon={regular("gear")} 
                                    color={options.color} 
                                    cursor={options.pointer ? 'pointer' : 'normal'} />

        case SwapSettingsButtonContent.swapSides:
            return <FontAwesomeIcon icon={icon({name: 'right-left'})} 
                                    color={options.color} 
                                    cursor={options.pointer ? 'pointer' : 'normal'} />

        case SwapSettingsButtonContent.toggleChart:
            return <FontAwesomeIcon icon={icon({name: 'chart-line'})} 
                                    color={options.color} 
                                    cursor={options.pointer ? 'pointer' : 'normal'} />

        case SwapSettingsButtonContent.refresh:
            return <FontAwesomeIcon icon={icon({name: 'refresh'})} 
                                    color={options.color} 
                                    cursor={options.pointer ? 'pointer' : 'normal'} />
    }
}



export const SwapSettingsButton = (props: SwapSettingsButtonProps) => {
    const buttonContent = getIconFromButtonContent({content: props.content, color: props.disabled ? '#555' : 'white', pointer: !props.disabled})
    
    const [refreshButtonRotation, setRefreshButtonRotation] = useState(0)

    const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (props.content === SwapSettingsButtonContent.refresh) {
            setRefreshButtonRotation(x => x + 360)
        }
        if (props.onClick) {
            props.onClick(e)
        }
    }

    return (
        <button disabled={props.disabled} className={styles.button} onClick={onClick} style={{transform: `rotate(${refreshButtonRotation}deg)`}}>
            {buttonContent}
        </button>
    )
}

export default SwapSettingsButton
