import axios from "axios"
import useAuth from "./useJwt"


const apiUrl = 'http://localhost:5282/api/'

function getFullEndpoint(endpoint: string) {
    return `${apiUrl}${endpoint}`
}

type ErrorResponse = {
    status: string,
    message: string
}
type AuthResponse = {
    expiration: string,
    token: string
}

export default function useBodaSwapApi() {
    const auth = useAuth()

    async function logIn(username: string, password: string) {
        const response = await axios.post(getFullEndpoint('auth/login'), {
            username,
            password
        }, {
            validateStatus: () => true
        })

        if (response.status !== 200) {
            throw new Error((response.data as ErrorResponse).message)
        }

        const newAuth = response.data as AuthResponse

        auth.setJwt({
            expiry: new Date(newAuth.expiration),
            token: newAuth.token
        })

        console.log("Logged in")
    }

    async function signUp(username: string, password: string, email: string) {
        const response = await axios.post(getFullEndpoint('auth/register'), {
            username,
            emailAddress: email,
            password
        }, {
            validateStatus: () => true
        })

        if (response.status !== 200) {
            throw new Error((response.data as ErrorResponse).message)
        }

        console.log("Registered")
    }

    async function logOut() {
        auth.setJwt(null)
    }

    return {
        auth,
        logIn,
        logOut,
        signUp
    }
}
