import PCSToken from "../../types/token";

import BODAV2Logo from '../../images/tokens/devel/BODAV2.png'
import BNBLogo from '../../images/tokens/devel/BNB.png'
import CAKELogo from '../../images/tokens/devel/CAKE.png'
import SDXLogo from '../../images/tokens/devel/SDX.png'
import AnationLogo from '../../images/tokens/devel/Anation.png'
import ApolloLogo from '../../images/tokens/devel/Apollo_256.png'
import CryptolicLogo from '../../images/tokens/devel/CPTLC.png'


export const BinanceTokens = [
    {
        name: 'Binance Coin',
        symbol: 'BNB',
        decimals: 18,
        address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
        logoUrl: BNBLogo,
        isNativeToken: true
    },
    {
        name: 'BODAV2',
        symbol: 'BODAV2',
        decimals: 18,
        address: '0xdc847755343c3a2b94d6afc0aae57651e1b14064',
        logoUrl: BODAV2Logo
    },
    {
        name: 'Cake',
        symbol: 'CAKE',
        decimals: 18,
        address: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
        logoUrl: CAKELogo
    },
    {
        name: 'Tether USD',
        symbol: 'USDT',
        decimals: 18,
        address: '0x55d398326f99059fF775485246999027B3197955',
        logoUrl: 'https://pancakeswap.finance/images/tokens/0x55d398326f99059fF775485246999027B3197955.png'
    },
    {
        name: 'SteakD',
        symbol: 'SDX',
        decimals: 18,
        address: '0x510AeB87665D3fCE5395a62045C5B7aE8990bf35',
        logoUrl: SDXLogo
    },
    {
        name: 'Bitcoin',
        symbol: 'BTCB',
        decimals: 18,
        address: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
        logoUrl: 'https://cryptologos.cc/logos/bitcoin-btc-logo.svg'
    },
    {
        name: 'Binance USD',
        symbol: 'BUSD',
        decimals: 18,
        address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
        logoUrl: 'https://bscscan.com/token/images/busd_32_2.png'
    },
    {
        name: 'A-Nation',
        symbol: 'ANATION',
        decimals: 9,
        address: '0x5Ae89b52f6B5D25EBA50f0441F91A81968689D95',
        logoUrl: AnationLogo
    },
    {
        name: 'Apollo Ventures',
        symbol: 'A11',
        decimals: 9,
        address: '0x400e6d89d6Ca49Ad999A2D19c8a7AFc711002A2A',
        logoUrl: ApolloLogo
    },
    {
        name: 'Axie Infinity Shard',
        symbol: 'AXS',
        decimals: 18,
        address: '0x715D400F88C167884bbCc41C5FeA407ed4D2f8A0',
        logoUrl: 'https://pancakeswap.finance/images/tokens/0x715D400F88C167884bbCc41C5FeA407ed4D2f8A0.png'
    },
    {
        name: 'Baby Doge Coin',
        symbol: 'BabyDoge',
        decimals: 9,
        address: '0xc748673057861a797275CD8A068AbB95A902e8de',
        logoUrl: 'https://assets.coingecko.com/coins/images/16125/small/Baby_Doge.png'
    },
    {
        name: 'Cryptolic',
        symbol: 'CPTLC',
        decimals: 18,
        address: '0x2f3a64922e82d23ced259545d4d13bdad97209ed',
        logoUrl: CryptolicLogo
    }
] as PCSToken[]
