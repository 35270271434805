import styles from './swapInputField.module.css'


type SwapInputFieldProps = {
    label?: string,

    placeholder?: string

    value?: string
    onUpdate?: (value: string) => void

    disabled?: boolean
}


export const SwapInputField = (props: SwapInputFieldProps) => {
    return (
        <div className={styles.container}>
            <label className={styles.label}>{props.label}</label>
            <input disabled={props.disabled} placeholder={props.placeholder} value={props.value} onChange={(e) => props.onUpdate ? props.onUpdate(e.target.value) : (()=>{})()} type='text' className={styles.inputField}/>
        </div>
    )
}

export default SwapInputField
