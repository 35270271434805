import './App.css'
import { ReactNode } from 'react'
import {
    BrowserRouter,
    Route,
    Routes,
    useLocation
} from "react-router-dom"
import Layout from './pages/layout.tsx'
import LoadingPage from './pages/loading.tsx'
import NotFound from './pages/notFound/index.tsx'
import SwapPage from './pages/home/index.tsx'
import PoolsPage from './pages/pools/index.tsx'
import ProModePage from './pages/pro/index.tsx'
import LandingPage from './pages/landing/index.tsx'
import { useState } from 'react'
import { ActivePopup, PopupContext } from './hooks/usePopups.ts'
import { AnimatePresence } from 'framer-motion'
import TopLevelLayout from './pages/topLevel.tsx'
import { AuthProvider } from './hooks/useJwt.tsx'
import AccountPage from './pages/account/index.tsx'


if (window.localStorage.getItem('customTokens') === null) {
    window.localStorage.setItem('customTokens', '[]')
}


function LocationProvider({ children }: { children: ReactNode }) {
    return ( 
        <AnimatePresence>
            {children}
        </AnimatePresence> 
    )
}
function RoutesWithAnimation() {
    const location = useLocation();
  
    return (
      <Routes location={location} key={location.key}>
        <Route path='/' element={<TopLevelLayout/>}>
            <Route path='/' element={<LandingPage/>} />
            <Route path='/' element={<Layout/>}>
                <Route path='/' element={<SwapPage/>} />
                <Route path='swap' element={<SwapPage/>} />
                <Route path='pools' element={<PoolsPage/>} />
                <Route path='pro' element={<ProModePage/>} />
                <Route path='/account' element={<AccountPage/> } />
            </Route>
        </Route>

        <Route path='/loading' element={<LoadingPage/>} />
        <Route path='*' element={<NotFound/>} />
      </Routes>
    )
}

function App() {
    const [activePopup, setActivePopup] = useState('None' as ActivePopup)

    return (
        <AuthProvider>
            <PopupContext.Provider value={{activePopup: activePopup, setPopup: setActivePopup, close: () => setActivePopup('None')}}>
                <BrowserRouter>
                    <LocationProvider>
                        <RoutesWithAnimation />
                    </LocationProvider>
                </BrowserRouter>
            </PopupContext.Provider>
        </AuthProvider>
    )
}

export default App
