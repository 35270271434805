import styles from './signUpPopup.module.css'

import BasePopup from '../../basePopup'
import usePopups from '../../../../../hooks/usePopups'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useEffect } from 'react'


export const ConfirmEmailPopup = () => {
    const popups = usePopups()

    return (
        <BasePopup showing={popups.activePopup === 'ConfirmEmail'} idealWidth='20rem' minWidth='13rem' title='' hideButton handleClose={popups.close}>
            <div className={styles.container}>
                <div className={styles.textContainer}>
                    <h2>Confirm Email</h2>

                    <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center', width: '100%'}}>
                        <FontAwesomeIcon icon={solid('envelope')} size='4x' />
                    </div>

                    <p style={{textAlign: 'center', color: 'white', fontSize: '1.1em'}}>We have sent a confirmation email to your email address. Please use the link to verify your email then <a className={styles.signUp} onClick={() => popups.setPopup('SignIn')}>Log In</a> to continue</p>
                </div>
            </div>
        </BasePopup>
    )
}
