// import styles from './index.module.css'

// import { CandlestickData, Time } from 'lightweight-charts'
// import { ProChartComponent } from '../../components/charting/ChartComponent'
// import ExampleData, { ExampleVolumeData } from '../../data/devel/example/exampleData'
// import { useEffect, useMemo, useState } from 'react'
// import { ethers } from 'ethers'
// import TradingStrategyClient, { TradingStrategyVolumeCandle } from '../../data/retrieval/tradingstrategy/TradingStrategyClient'
// import UpdatePCSChartData from '../../data/processing/updatePCSChartData'
// import TokenSelectionPopup from '../../components/bodaswap/popups/tokenSelectionPopup'
// import { ConfirmSwapPopup } from '../../components/bodaswap/popups/swapping/confirmSwapPopup'
// import { ConfirmApproveTokensPopup } from '../../components/bodaswap/popups/swapping/confirmApproveTokensPopup'
// import { SwapFailedPopup } from '../../components/bodaswap/popups/swapping/swapFailedPopup'
// import { ConfirmApproveSwapPopup } from '../../components/bodaswap/popups/swapping/confirmApproveSwapPopup'
// import { SwapSucceededPopup } from '../../components/bodaswap/popups/swapping/swapSucceededPopup'
// import { SwapSettings } from '../../components/bodaswap/popups/settings/settingsPopup'
// import CurrencySelectPopup from '../../components/bodaswap/popups/currencySelectPopup'
// import ProChartReadout from '../../components/pro/chart/proChartReadout'
// import ProSwapPanel from '../../components/pro/swap/swapPanel'
// import ProTopReadout from '../../components/pro/layout/topReadout'
// import ProTokenomicsWidget from '../../components/pro/widgets/tokenomicsWidget'
// import ProBottomReadout from '../../components/pro/layout/bottomReadout'
// import { SwapTaOptionButtonContent } from '../../components/pro/buttons/swapTaOptionButton'
// import ProWrapperWidget from '../../components/pro/widgets/wrapperWidget'
// import useSwap from '../../hooks/useSwap'
// import { useNetwork } from 'wagmi'
// import PCSToken from '../../types/token'
// import { ERC20Token, computePairAddress } from '@pancakeswap/sdk'


export default function Swap() {
    return (
        <></>
    )
}

// export const Swap = () => {
//     const { activeCurrency, chartSeries, chartAvailable, tokens, baseToken, handleCurrencySelectClicked, inputValue, outputValue, handleInputValueChanged, handleOutputValueChanged, lastPrice, showChart, setInvertPanel, setShowChart, handleTokenSelected, handleTokenClicked, handleSwapTokens, handleSettingsClicked, handleRefreshClicked, setShowConfirmSwapPopup, tokenABalance, tokenARawLoading, tokenAIsRefetching, tokenBBalance, tokenBRawLoading, tokenBIsRefetching, tokenA, tokenB, showTokenSelectionPopup, tokenASelected, tokenList, handleCloseTokensList, showConfirmApprovalPopup, showSwapFailedPopup, setShowSwapFailedPopup, showApproveSwapPopup, showSwapSuccessPopup, setShowSwapSuccessPopup, showSettings, setShowSettings, handleCloseCurrencySelect, currencySelectPopup, setActiveCurrency, invertPanel, performSwap, showConfirmSwapPopup, lastProtocol, swapLoading, blockConfirmationPopup, setShowBlockConfirmationPopup, setChartSeries } 
//         = useSwap()

//     const [localData, setLocalData] = useState([] as CandlestickData[])
//     useEffect(() => {
//         async function fetchData() {
//             //const data = await fetch(`http://localhost:5253/api/web3/price-data/${getPairAddressPCS(tokenA, tokenB)}`)
//             const data = await fetch(`http://localhost:5253/api/web3/price-data/0x16b9a82891338f9ba80e2d6970fdda79d1eb0dae`)

//             const jsonObj = await data.json()
//             console.log(jsonObj)
//             const priceData = jsonObj.result as {
//                 open: number,
//                 high: number,
//                 low: number,
//                 close: number,
//                 blockNumber: number,
//                 txHash: string,
//                 pricePointCount: number
//             }[]

//             priceData.sort(x => x.blockNumber)

//             const seriesData = priceData.map(dataPoint => ({
//                 time: dataPoint.blockNumber,
//                 high: dataPoint.high,
//                 low: dataPoint.low,
//                 open: dataPoint.open,
//                 close: dataPoint.close
//             })) as CandlestickData[]
            
//             const formattedData = [...new Map(seriesData.map(v => [v.time, v])).values()]

//             setLocalData(formattedData)
//         }
//         fetchData()
//     }, [tokenA, tokenB])

//     const { chain } = useNetwork()

//     // Injected hooks
//     //const web3 = useWeb3()

//     // Data / Charting
//     const [dataClient] = useState(new TradingStrategyClient())
//     const [volumeData, setVolumeData] = useState(ExampleVolumeData as {time: Time, value: number, color: string}[])

//     // Charting options
//     const [showMovingAverage, setShowMovingAverage] = useState(true)
//     const [showArea, setShowArea] = useState(false)
//     const [showPriceLines, setShowPriceLines] = useState(true)
//     const [showVolume, setShowVolume] = useState(false)
//     const [magnetMode, setMagnetMode] = useState(false)
//     const chartTaButtons = [
//         {
//             value: showMovingAverage,
//             setValue: setShowMovingAverage,
//             content: SwapTaOptionButtonContent.MovingAverage
//         },
//         {
//             value: showArea,
//             setValue: setShowArea,
//             content: SwapTaOptionButtonContent.Area
//         },
//         {
//             value: showPriceLines,
//             setValue: setShowPriceLines,
//             content: SwapTaOptionButtonContent.PriceLines
//         },
//         {
//             value: showVolume,
//             setValue: setShowVolume,
//             content: SwapTaOptionButtonContent.Volume
//         },
//         {
//             value: magnetMode,
//             setValue: setMagnetMode,
//             content: SwapTaOptionButtonContent.Magnet
//         }
//     ]


//     // Update charting
//     useEffect(() => {
//         async function updateChartData() {
//             try {
//                 const data = await UpdatePCSChartData(dataClient, tokens, chain?.id ?? 56, new Date(0))
            
//                 setChartSeries(data.priceData)
//                 setVolumeData(convertVolumeData(data.volumeData))

//                 /*setBaseToken(data.baseToken)
//                 setChartAvailable(true)
//                 setLastPrice(Number.parseFloat(data.priceData[0].data[data.priceData.length - 1].y[3].toPrecision(4)))*/ 
//             }
//             catch (e) { console.warn("Failed to get charting data", e) }
//         }
//         updateChartData()
//     }, [tokenA, tokenB, dataClient, chain])

//     const chartComponentMemo = useMemo(() => <ProChartComponent 
//                                                 taOptions={{showMovingAverage, showArea, showPriceLines, showVolume, magnetMode}} 
//                                                 volumeData={volumeData} 
//                                                 data={localData} 
//                                                 width='100%' 
//                                                 height='100%' />, 
//                                             [showMovingAverage, showArea, showPriceLines, showVolume, magnetMode, volumeData, localData])

//     // Return JSX
//     return (
//         <>
//             {/* Swap Popups.. TODO: Move?? */}
//             { <TokenSelectionPopup showing={showTokenSelectionPopup} selectedTokens={tokens} onTokenSelected={handleTokenSelected} inputTokenLastSelected={tokenASelected} tokens={tokenList} handleClose={handleCloseTokensList}/>}
//             { <ConfirmSwapPopup showing={showConfirmSwapPopup} outputIsExact={/*baseOnOutput*/false} onConfirm={performSwap} quantities={{inputReadout: ethers.utils.formatUnits(inputValue, tokenA?.decimals), outputReadout: ethers.utils.formatUnits(outputValue, tokenB?.decimals)}} tokens={tokens} handleClose={() => setShowConfirmSwapPopup(false)} />}
//             { <ConfirmApproveTokensPopup showing={showConfirmApprovalPopup} tokens={tokens} />}
//             { <SwapFailedPopup showing={showSwapFailedPopup} handleClose={() => setShowSwapFailedPopup(false)} /> }
//             { <ConfirmApproveSwapPopup showing={showApproveSwapPopup} />}
//             { <SwapSucceededPopup showing={showSwapSuccessPopup} handleClose={() => setShowSwapSuccessPopup(false)} />}
//             { <SwapSettings showing={showSettings} handleClose={() => setShowSettings(false)} />}
//             { <CurrencySelectPopup showing={currencySelectPopup} handleSetCurrency={(currency: [string, number]) => { setActiveCurrency(currency) }} handleClose={() => handleCloseCurrencySelect() } /> }
//             {/* Main Content */}

//             <div className={styles.tokenPanelLayout}>
//                 <ProTokenomicsWidget />
//             </div>


//             <div className={styles.chartSection}>
//                 <ProChartReadout buttons={chartTaButtons} series={chartSeries} tokens={tokens} baseCrypto={baseToken} />

//                 <div className={styles.chartWrapper}>
//                     {chartComponentMemo}
//                 </div>
//             </div>

//             <div className={styles.swapPanelLayout}>
//                 <ProSwapPanel
//                     inputs={{inputValue, outputValue, handleInputValueChanged, handleOutputValueChanged}} 
//                     lastPrice={lastPrice} 
//                     disableButtons={{disableSwapSides: false}} 
//                     tokens={tokens} 
//                     setters={{setSwapSides: () => {}, setViewChart: () => {}, handleTokenSelected, handleTokenClicked, handleSwapTokens, handleSettingsClicked, handleRefreshClicked}}
//                     handleConfirmSwap={() => setShowConfirmSwapPopup(true)}
//                     balances={{inputBalance: tokenABalance, outputBalance: tokenBBalance}}
//                     handleSpendButtons={{
//                         twentyFivePercentClicked: () => { handleInputValueChanged(ethers.utils.formatUnits(tokenABalance.div(4).toString(), tokenA.decimals)) },
//                         fiftyPercentClicked: () => { handleInputValueChanged(ethers.utils.formatUnits(tokenABalance.div(2).toString(), tokenA.decimals)) },
//                         maxButtonClicked: () => { handleInputValueChanged(ethers.utils.formatUnits(tokenABalance.toString(), tokenA.decimals)) }
//                     }}
//                     liquidityProtocolReadoutContent={lastProtocol ?? '-'}/>
//                 <ProWrapperWidget />
//             </div>
//         </>
//     )
// }



// export const ProModePage = () => {
//     return (
//         <>
//             <div className={styles.background}/>

//             <div className={styles.container}>
//                 <ProTopReadout />

//                 <div className={styles.swapContainer}>
//                     <Swap />
//                 </div>

//                 <ProBottomReadout />
//             </div>
//         </>
//     )
// }

// function convertData(inputData: ApexAxisChartSeries) {
//     return (inputData[0].data as {x: string, y: number[]}[]).map(x =>
//         ({
//             time: typeof(x.x) === 'number' ? x.x / 1000 : x.x,
//             open: x.y[0],
//             high: x.y[1],
//             low: x.y[2],
//             close: x.y[3]
//         })) as CandlestickData[]
// }
// function convertDataAlternate(inputData: ApexAxisChartSeries) {
//     return (inputData[0].data as {x: number, y: number}[]).map(dataPoint =>
//         ({
//             time: dataPoint.x,
//             value: dataPoint.y
//         }))
// }
// function convertVolumeData(inputData: TradingStrategyVolumeCandle[]) {
//     var lastCandle = 0
//     return inputData.map(x => {
//         const thisCandle = x.c - lastCandle
//         lastCandle = x.c
//         return {
//             time: new Date(x.ts).getTime() / 1000 as Time,
//             value: Math.abs(thisCandle),
//             color: thisCandle >= 0 ? '#003d01' :  '#520000'
//         }
//     })
// }


// function getPairAddressPCS(tokenA: PCSToken, tokenB: PCSToken): string {
//     return computePairAddress({factoryAddress: '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73', tokenA: new ERC20Token(56, tokenA.address as `0x${string}`, tokenA.decimals, tokenA.symbol, tokenA.name), tokenB: new ERC20Token(56, tokenB.address as `0x${string}`, tokenB.decimals, tokenB.symbol, tokenB.name)})
// }

// export default ProModePage
