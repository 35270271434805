
export enum SwapStep {
    OverviewSwap = 0,
    ApproveTokens = 1,
    ConfirmSwap = 2,
    Success = 3,
    Failure = 4,
    BlockConfirmations = 5
}

export default SwapStep
