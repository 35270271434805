import PCSToken from "../../../types/token";
import axios, { AxiosResponse } from 'axios';
import * as Pancake from '@pancakeswap/sdk'
import * as Uniswap from '@uniswap/v3-sdk'
import * as UniswapCore from '@uniswap/sdk-core'


export const ChainSlug = {
    1: 'ethereum',
    56: 'binance',
    137: 'polygon',
    42161: 'arbitrum'
}
export const ExchangeSlug = {
    56: 'pancakeswap-v2',
    1: 'uniswap-v2',
    137: 'uniswap-v3',
    42161: 'uniswap-v3'
}
export const ExchangeType = {
    56: 'uni_v2',
    1: 'uniswap_v3',
    137: 'uniswap_v3',
    42161: 'uniswap_v3'
}

type TimeBucket = '1m' | '5m' | '15m' | '1h' | '4h' | '1d' | '7d' | '30d'

export type TradingStrategyCandle = {
    ts: string,
    o: number,
    h: number,
    l: number,
    c: number,
    v: number,
    xr: number,
    b: number,
    s: number,
    tc: number,
    bv: number,
    sv: number
}

export type TradingStrategyVolumeCandle = {
    ts: string,
    o: number,
    h: number,
    l: number,
    c: number,
    a: number,
    r: number,
    av: number,
    rv: number,
    xr: number,
    tc: number
}


export class TradingStrategyClient {
    private baseAddress = 'https://tradingstrategy.ai/api/'
    private endpoints = {
        pairDetails: 'pair-details',
        candles: 'candles',
        liquidity: 'xyliquidity'
    }

    public async fetchPairData(exchangeSlug: string, chainSlug: string, pairSlug: string): Promise<{pairId: number, baseTokenSymbol: string}> {
        const result = await axios.get(`${this.endpoints.pairDetails}`, {baseURL: this.baseAddress, params: {
            exchange_slug: exchangeSlug,
            chain_slug: chainSlug,
            pair_slug: pairSlug
        }})
        
        return {
            pairId: result.data.summary.pair_id,
            baseTokenSymbol: result.data.summary.base_token_symbol
        }
    }

    public async getPriceData(pairId: number, timeBucket: TimeBucket, startTime: Date, exchangeType: string): Promise<AxiosResponse<any, any>> {
        const result = await axios.get(this.endpoints.candles, {
            baseURL: this.baseAddress,
            params: {
                pair_id: pairId,
                time_bucket: timeBucket,
                start: startTime.toISOString().slice(0, -1),
                end: '', // Now
                exchange_type: exchangeType
            }
        })

        return result
    }

    public async getVolumeData(pairId: number, timeBucket: TimeBucket, startTime: Date, exchangeType: string) {
        const result = await axios.get<TradingStrategyVolumeCandle[]>(this.endpoints.liquidity, {
            baseURL: this.baseAddress,
            params: {
                pair_id: pairId,
                time_bucket: timeBucket,
                start: startTime.toISOString().slice(0, -1),
                end: '', // Now
                exchange_type: exchangeType
            }
        })

        return result
    }

    public getPairAddressPCS(tokenA: PCSToken, tokenB: PCSToken): string {
        return Pancake.computePairAddress({factoryAddress: '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73', tokenA: new Pancake.ERC20Token(56, tokenA.address as `0x${string}`, tokenA.decimals, tokenA.symbol, tokenA.name), tokenB: new Pancake.ERC20Token(56, tokenB.address as `0x${string}`, tokenB.decimals, tokenB.symbol, tokenB.name)})
    }
    public getPairAddressUniswap(tokenA: PCSToken, tokenB: PCSToken, chainId: number): string {
        return Uniswap.computePoolAddress({factoryAddress: '0x1F98431c8aD98523631AE4a59f267346ea31F984', tokenA: new UniswapCore.Token(chainId, tokenA.address, tokenA.decimals, tokenA.symbol, tokenA.name), tokenB: new UniswapCore.Token(chainId, tokenB.address, tokenB.decimals, tokenB.symbol, tokenB.name), fee: Uniswap.FeeAmount.LOW})
    }

}

export default TradingStrategyClient
