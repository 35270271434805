import styles from './blockNumberReadout.module.css'

import { Tooltip } from 'react-tooltip'
import { useBlockNumber } from 'wagmi'


export const BlockNumberReadout = () => {
    const { data: blockNumber } = useBlockNumber()

    return (
        <>
            <div className={styles.container} data-tooltip-id="block-number-readout-tooltip" data-tooltip-content="Current block number from the provider">
                <div className={styles.indicator} style={{backgroundColor: blockNumber ?? 0 > 0 ? 'green' : 'red'}}/>
                <p>{(blockNumber ?? 0) > 0 ? blockNumber!.toString() : '-'}</p>
            </div>
            <Tooltip id="block-number-readout-tooltip" style={{zIndex: '1000001'}} />
        </>
    )
}
