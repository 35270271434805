import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PCSToken from '../../../types/token'
import TokenButton from '../ui/buttons/tokenButton'
import styles from './tokenSelectionPanel.module.css'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useAccount, useWalletClient } from 'wagmi'


type TokenSelectionPanelProps = {
    onClicked: (token: PCSToken) => void
    token: PCSToken
    balanceReadout: string
}


// async function addToken(token: PCSToken) {
//     try {
//         const wasAdded = await web3.provider.provider.request({
//             method: 'wallet_watchAsset',
//             params: {
//                 type: 'ERC20',
//                 options: {
//                     address: token.address,
//                     symbol: token.symbol,
//                     decimals: token.decimals,
//                     image: `${window.location.origin}${token.logoUrl}`
//                 }
//             }
//         })
//     } catch (e) {
//         console.warn(e)
//     }
// }


export const TokenSelectionPanel = (props: TokenSelectionPanelProps) => {
    const client = useWalletClient()
    const { address } = useAccount()


    return (
        <div className={styles.container}>
            <TokenButton onTokenClicked={props.onClicked} token={props.token}/>
            {!props.token.isNativeToken && address &&
                <FontAwesomeIcon 
                    icon={solid('plus-circle')} // TODO Add AddToken
                    style={{marginRight: 'auto', marginLeft: '0.5em', cursor: 'pointer'}}
                    onClick={() => {
                        try {
                            client.data?.watchAsset({
                                type: 'ERC20',
                                options: {
                                    address: props.token.address,
                                    symbol: props.token.symbol,
                                    decimals: props.token.decimals,
                                    image: undefined
                                }
                            })
                        }
                        catch (e) {
                            console.warn("Couldn't add token to user wallet: ", e)
                        }
                    }} />
            }

            <div className={styles.balanceContainer}>
                <b><p>Balance</p></b>
                <p>{props.balanceReadout}</p>
            </div>
        </div>
    )
}

export default TokenSelectionPanel
