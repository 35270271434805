import { useState } from 'react'
import BasePopup from '../basePopup'
import styles from './settingsPopup.module.css'
import useSwap from '../../../../hooks/context/swapContext'


const SelectOption = (props: { option: MultiSelectOption }) => {
    const [selected, setSelected] = useState(false)

    return (
        <div onClick={() => setSelected(x => !x)} className={styles.selectOptionContainer}>
            <input type='checkbox' checked={selected} onChange={(e) => setSelected(e.target.checked)} />
            <p>{props.option.name}</p>
        </div>
    )
}

type MultiSelectOption = {
    name: string
    value: any
    handleClicked: (value: any) => void
}
const MultiSelect = (props: { options: MultiSelectOption[] }) => {
    return (
        <div>
            {props.options.map(x => {
                return <SelectOption key={x.name} option={x} />
            })}
        </div>
    )
}

export const SwapSettings = () => {
    const { showSettings: showing, setShowSettings } = useSwap()
    const handleClose = () => setShowSettings(false)

    return (
        <BasePopup showing={showing} title='Swap Settings' minWidth='15em' idealWidth='20em' handleClose={handleClose}>
            <h4>Gas Price</h4>
            <MultiSelect options={[
                {
                    name: 'Standard (5)',
                    value: null,
                    handleClicked: x => {}
                },
                {
                    name: 'Fast (6)',
                    value: null,
                    handleClicked: x => {}
                },
                {
                    name: 'Ultra Fast (7)',
                    value: null,
                    handleClicked: x => {}
                }
            ]} />

            <h4>Max Slippage</h4>
            <MultiSelect options={[
                {
                    name: '0%',
                    value: null,
                    handleClicked: x => {}
                },
                {
                    name: '0.5%',
                    value: null,
                    handleClicked: x => {}
                },
                {
                    name: '1%',
                    value: null,
                    handleClicked: x => {}
                },
                {
                    name: '5%',
                    value: null,
                    handleClicked: x => {}
                },
                {
                    name: '20%',
                    value: null,
                    handleClicked: x => {}
                },
                {
                    name: 'Unlimited',
                    value: null,
                    handleClicked: x => {}
                }
            ]} />

            <h4>Transaction Deadline</h4>
            <MultiSelect options={[
                {
                    name: '5 mins',
                    value: null,
                    handleClicked: x => {}
                },
                {
                    name: '20 mins',
                    value: null,
                    handleClicked: x => {}
                },
                {
                    name: '60 mins',
                    value: null,
                    handleClicked: x => {}
                }
            ]} />
        </BasePopup>
    )
}
