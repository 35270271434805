import styles from './swapFailedPopup.module.css'

import BasePopup from '../basePopup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import usePopups from '../../../../hooks/usePopups'


type ConfirmApproveSwapPopupProps = {
}
export const ConfirmApproveSwapPopup = (props: ConfirmApproveSwapPopupProps) => {
    const popups = usePopups()

    return (
        <BasePopup showing={popups.activePopup === 'ApproveSwap'} hideButton idealWidth='20rem' minWidth='13rem' title='Confirm Swap'>
            <div className={styles.textContainer}>
                <div style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                    <p style={{color: 'white'}}>Please confirm the swap in your wallet</p>
                    <FontAwesomeIcon icon={solid('spinner-third')} color='lightgreen' className='fa-spin' style={{height: '2em', width: '2em'}}/>
                </div>
            </div>
        </BasePopup>
    )
}
