import styles from './currencySelectPopup.module.css'

import CurrencyConversions from "../../../data/devel/currencies/currencyData"
import BasePopup from "./basePopup"
import usePopups from '../../../hooks/usePopups'
import useSwap from '../../../hooks/context/swapContext'


const CurrencyListing = (props: { currency: [string, number], onClick: (currency: [string, number]) => void }) => {
    return (
        <button onClick={() => props.onClick(props.currency)} className={styles.tokenListing}>
            {/*<img className={styles.tokenIcon} src={props.token.logoUrl} alt='' />*/}
            <div className={styles.tokenDetails}>
                <h4>{props.currency[0]}</h4>
            </div>
        </button>
    )
}

export const CurrencySelectPopup = () => {
    const popups = usePopups()

    const { setActiveCurrency } = useSwap()
    const handleSetCurrency = (currency: [string, number]) => { setActiveCurrency(currency) }

    const currencies = Object.entries(CurrencyConversions.rates)

    return (
        <BasePopup showing={popups.activePopup === 'CurrencySelect'} title='Select Currency' handleClose={popups.close} minWidth='21em'>
            <div className={styles.content}>
                { currencies.map((x, idx) => <CurrencyListing onClick={(currency: [string, number]) => { handleSetCurrency(currency); popups.close() }} currency={x} key={idx} /> ) }
            </div>
        </BasePopup>
    )
}

export default CurrencySelectPopup
