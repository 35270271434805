import styles from './bodaSwap.module.css'

import { WaitingForConfirmationPopup } from './popups/swapping/blockConfirmationWaiting'
import { ConfirmApproveTokensPopup } from './popups/swapping/confirmApproveTokensPopup'
import { ConfirmApproveSwapPopup } from './popups/swapping/confirmApproveSwapPopup'
import { SwapSucceededPopup } from './popups/swapping/swapSucceededPopup'
import { ConfirmSwapPopup } from './popups/swapping/confirmSwapPopup'
import { SwapFailedPopup } from './popups/swapping/swapFailedPopup'
import { SwapSettings } from './popups/settings/settingsPopup'
import { ChartPanel } from './panels/chartPanel'
import { SwapPanel } from './panels/swapPanel'
import TokenSelectionPopup from './popups/tokenSelectionPopup'
import CurrencySelectPopup from './popups/currencySelectPopup'
import { SignInPopup } from './popups/user/login/loginPopup'
import SwapSplitView from './layout/splitView'

import { motion } from 'framer-motion'

import GlobalHeader from '../inpage/headers/globalHeader'
import useSwap from '../../hooks/context/swapContext'


export const BodaSwap = () => {
    const { showChart, invertPanel } = useSwap()

    // Panels
    const chartPanel = <ChartPanel/>
    const swapPanel =  <SwapPanel/>


    const items = []

    items.push(swapPanel)
    if (showChart) {
        items.push(chartPanel)
    }


    // Return JSX
    return (
        <>

            {/* Swap Popups.. TODO: Move?? */}
            <TokenSelectionPopup />
            <ConfirmSwapPopup />
            <ConfirmApproveTokensPopup />
            <SwapFailedPopup /> 
            <ConfirmApproveSwapPopup />
            <SwapSucceededPopup />
            <SwapSettings />
            <CurrencySelectPopup />
            <WaitingForConfirmationPopup />

            {/* Main Content */}
            <motion.div className={styles.background} initial={{opacity: 0}} animate={{opacity: 1}} transition={{delay: 0.15}}>
                <GlobalHeader />

                <div className={styles.container}>
                    <SwapSplitView sides={items} flip={invertPanel} />
                </div>
            </motion.div>
        </>
    )
}

export default BodaSwap
