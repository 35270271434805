export const CurrencyConversions = 
{
    success: true,
    timestamp: 1685543583,
    base: "USD",
    date: "2023-05-31",
    rates: {
        AED: 3.67301,
        AFN: 87.166571,
        ALL: 102.738123,
        AMD: 386.620441,
        ANG: 1.80117,
        AOA: 583.74554,
        ARS: 239.372768,
        AUD: 1.545249,
        AWG: 1.8,
        AZN: 1.701624,
        BAM: 1.831153,
        BBD: 2.01822,
        BDT: 107.202711,
        BGN: 1.829495,
        BHD: 0.377005,
        BIF: 2820.748092,
        BMD: 1,
        BND: 1.354686,
        BOB: 6.906786,
        BRL: 5.087399,
        BSD: 0.999747,
        BTC: 0.000037090245,
        BTN: 82.68669,
        BWP: 13.787486,
        BYN: 2.523079,
        BYR: 19600,
        BZD: 2.014811,
        CAD: 1.36259,
        CDF: 2335.000216,
        CHF: 0.91218,
        CLF: 0.029297,
        CLP: 808.398728,
        CNY: 7.110803,
        COP: 4407.6,
        CRC: 539.753577,
        CUC: 1,
        CUP: 26.5,
        CVE: 103.232934,
        CZK: 22.258977,
        DJF: 177.945238,
        DKK: 6.97971,
        DOP: 54.631407,
        DZD: 136.897024,
        EGP: 30.896199,
        ERN: 15,
        ETB: 54.279736,
        EUR: 0.93716,
        FJD: 2.25945,
        FKP: 0.806352,
        GBP: 0.80787,
        GEL: 2.594998,
        GGP: 0.806352,
        GHS: 11.369935,
        GIP: 0.806352,
        GMD: 59.550299,
        GNF: 8592.133474,
        GTQ: 7.815586,
        GYD: 211.407385,
        HKD: 7.833395,
        HNL: 24.576105,
        HRK: 6.94434,
        HTG: 139.942701,
        HUF: 347.614954,
        IDR: 15004,
        ILS: 3.727105,
        IMP: 0.806352,
        INR: 82.70665,
        IQD: 1309.464735,
        IRR: 42324.999853,
        ISK: 140.310053,
        JEP: 0.806352,
        JMD: 154.373976,
        JOD: 0.709302,
        JPY: 139.920504,
        KES: 138.5894,
        KGS: 87.589675,
        KHR: 4113.962568,
        KMF: 459.450387,
        KPW: 899.885664,
        KRW: 1325.570242,
        KWD: 0.30782,
        KYD: 0.832998,
        KZT: 447.601217,
        LAK: 17734.62662,
        LBP: 15003.651412,
        LKR: 288.73846,
        LRD: 168.375015,
        LSL: 19.710289,
        LTL: 2.95274,
        LVL: 0.60489,
        LYD: 4.820845,
        MAD: 10.164381,
        MDL: 17.789843,
        MGA: 4388.248071,
        MKD: 57.673857,
        MMK: 2099.113354,
        MNT: 3473.597846,
        MOP: 8.066343,
        MRO: 356.999828,
        MUR: 45.697199,
        MVR: 15.309683,
        MWK: 1025.953112,
        MXN: 17.648301,
        MYR: 4.615501,
        MZN: 63.249695,
        NAD: 19.710083,
        NGN: 461.097109,
        NIO: 36.561616,
        NOK: 11.15384,
        NPR: 132.278025,
        NZD: 1.66671,
        OMR: 0.385022,
        PAB: 0.999541,
        PEN: 3.673859,
        PGK: 3.592792,
        PHP: 56.35497,
        PKR: 285.357922,
        PLN: 4.246044,
        PYG: 7268.89773,
        QAR: 3.640501,
        RON: 4.65663,
        RSD: 109.950083,
        RUB: 81.097975,
        RWF: 1127.641447,
        SAR: 3.750896,
        SBD: 8.333912,
        SCR: 12.545641,
        SDG: 601.000021,
        SEK: 10.90573,
        SGD: 1.354675,
        SHP: 1.21675,
        SLE: 22.572049,
        SLL: 19750.000177,
        SOS: 568.474966,
        SRD: 37.256498,
        STD: 20697.981008,
        SVC: 8.746618,
        SYP: 2512.513556,
        SZL: 19.758438,
        THB: 34.69991,
        TJS: 10.913644,
        TMT: 3.5,
        TND: 3.08875,
        TOP: 2.381397,
        TRY: 20.701302,
        TTD: 6.786945,
        TWD: 30.7225,
        TZS: 2365.000237,
        UAH: 36.910367,
        UGX: 3778.849305,
        USD: 1,
        UYU: 38.789592,
        UZS: 11395.320531,
        VEF: 2622193.46998,
        VES: 26.131195,
        VND: 23492.5,
        VUV: 120.705056,
        WST: 2.740443,
        XAF: 614.151694,
        XAG: 0.042872,
        XAU: 0.000508,
        XCD: 2.70255,
        XDR: 0.750707,
        XOF: 614.151694,
        XPF: 111.82499,
        YER: 250.313532,
        ZAR: 19.71866,
        ZMK: 9001.198714,
        ZMW: 19.491986,
        ZWL: 321.999592
    }
}

export default CurrencyConversions;
