
import USDCLogo from '../../images/tokens/devel/USDC.svg'
import AvaxLogo from '../../images/tokens/devel/AVAX.svg'



export const AvalancheTokens = [
    {
        name: 'Avalanche',
        symbol: 'AVAX',
        decimals: 18,
        address: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
        logoUrl: AvaxLogo,
        isNativeToken: true
    },
    {
        name: 'USD Coin',
        symbol: 'USDC',
        decimals: 6,
        address: '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664',
        logoUrl: USDCLogo
    },
]
