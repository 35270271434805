import styles from './index.module.css'

import glassyStyles from '../../styles/neumorphic/glassy.module.css'
import shinyStyles from '../../styles/shine/shiny.module.css'

import { motion } from "framer-motion"
import GlobalHeader from "../../components/inpage/headers/globalHeader"
import useBodaSwapApi from '../../hooks/useBodaSwapApi'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import usePopups from '../../hooks/usePopups'
import { useEffect, useState } from 'react'

function WalletReadout() {
    const [divRef, setDivRef] = useState<HTMLDivElement | null>(null)
    const [width, setWidth] = useState(window.innerWidth)

    function handleResize() {
        setWidth(divRef?.clientWidth ?? window.innerWidth)
    }

    useEffect(() => {
        if (!divRef)
            return

        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [divRef])

    useEffect(() => {
        console.log(width)
    }, [width])

    return (
        <div ref={(newRef) => setDivRef(newRef)} className={styles.walletReadout}>
            <FontAwesomeIcon icon={solid('wallet')} />

            {
                width > 600 ? (
                    <p>0xB5074Fb0ca334A139D3ccaEB6197A4E32a6f6367</p>
                ) : (
                    <p>0xB507...a6f6367</p>
                )
            }

            <button className={`${glassyStyles.darkGlassy} ${styles.button}`}>
                <FontAwesomeIcon icon={solid('minus-circle')} />
            </button>
        </div>
    )
}

function AddWalletButton() {
    const popups = usePopups()

    function onClick() {
        popups.setPopup('AddWallet')
    }

    return (            
        <button onClick={onClick} className={`${glassyStyles.darkGlassy} ${styles.button}`}>
            <FontAwesomeIcon icon={solid('plus-circle')} />
        </button>
    )
}

export default function AccountPage() {
    const api = useBodaSwapApi()

    async function logOut() {
        await api.logOut()

        window.location.href = '/'
    }

    return (
        <motion.div initial={{opacity: 0}} animate={{transition: {duration: 0.5, delay: 0.1}, opacity: 1}}>
            <motion.div initial={{backgroundColor: '#000'}}
                        animate={{transition: {duration: 1, delay: 0.75}, backgroundColor: '#001a04'}} 
                        className={styles.container}>
                <GlobalHeader />

                <div style={{height: '10rem'}} />

                <div className={`${styles.horizontalSection}`}>
                    <div className={styles.verticalSection}>
                        <h4>Your $B Points</h4>
                        <h1 style={{fontSize: '5em'}}>3441</h1>
                        <button className={`${glassyStyles.darkGlassy} ${styles.button}`} style={{marginTop: '1em'}}>
                            Withdraw
                        </button>
                    </div>
                </div>

                <div style={{height: '10rem'}} />

                <div className={`${styles.horizontalSection}`}>
                    <div className={styles.verticalSection}>
                        <h2>Wallets</h2>
                        <div className={styles.walletContainer}>
                            <WalletReadout />
                            <WalletReadout />

                            <AddWalletButton />
                        </div>
                    </div>
                </div>

                <div style={{height: '10rem'}} />

                <div className={`${styles.horizontalSection}`}>                        
                    <button 
                    className={`${glassyStyles.darkGlassy} ${styles.button}`} 
                    style={{marginTop: '1em'}}
                    onClick={logOut}>
                        Log Out
                    </button>
                </div>
            </motion.div>
        </motion.div>
    )
}
