import styles from './swapHeader.module.css';
import BodaSwapLogo from '../../../../images/boda/BodaSwap.png'

type SwapHeaderProps = {
    text: string
}

export const SwapHeader = (props: SwapHeaderProps) => {
    return (
        <div className={styles.container}>
            <img src={BodaSwapLogo} />
            {/* <h1>{props.text}</h1> */}
        </div>
    )
}
