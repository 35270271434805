import * as Core from '@uniswap/sdk-core'
import * as SmartRouter from '@uniswap/smart-order-router'

import PCSToken from "../types/token"
import { BigNumber, ethers } from "ethers"
import SwapStep from "../types/enums/swapStep"
import { useState, useEffect, useRef } from 'react'
import { Config, useAccount, useConfig, usePublicClient, useReadContract, useWriteContract } from 'wagmi'

import { AlphaRouter } from '@uniswap/smart-order-router'
import JSBI from 'jsbi'
import { WriteContractMutateAsync } from 'wagmi/query'
import SupportedNetworks from '../web3/config/networks'

import BodaSwapABI from '../web3/abi/bodaSwapAbi_v2'
import { erc20Abi } from 'viem'
import { ReadContractErrorType } from 'wagmi/actions'
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query'



const getLibrary = (provider: any) => {
	return new ethers.providers.Web3Provider(provider, 'any')
}


async function performSwap(provider: any, chainId: number | undefined, account: string, tokens: { tokenA: PCSToken; tokenB: PCSToken; }, quantities: { inputAmount: BigNumber; outputAmount: BigNumber; }, baseOnOutput: boolean,
    writeContractAsync: WriteContractMutateAsync<Config, unknown>, handleSetStep: (newStep: SwapStep) => void, referrer: `0x${string}` | undefined | null, publicClient: any, 
    refetchAllowance: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<bigint, ReadContractErrorType>>, route: SmartRouter.SwapRoute | null, config: Config) {

    if (chainId === undefined) {
        console.error("Invalid ChainID, can't swap")
        handleSetStep(SwapStep.Failure)
        return
    }

    if (!route || !route.methodParameters) {
        console.warn("Routing failed")
        return
    }

    switch (route.route[0].protocol) {
        case 'V2':
            v2Swap(account, route, writeContractAsync, handleSetStep, chainId)
            break

        case 'V3':
            v3Swap(account, route, writeContractAsync, handleSetStep, chainId, quantities.inputAmount, referrer, publicClient, refetchAllowance, tokens.tokenA, tokens.tokenB, config)
            break

        default:
        case 'MIXED':
            console.error('Attempted to use MIXED swap mode, not supported')
            handleSetStep(SwapStep.Failure)
            break
    }
}

async function getApproval(inputToken: PCSToken, writeContractAsync: WriteContractMutateAsync<Config, unknown>, chainId: number, amountIn: bigint, routerAddress: `0x${string}`) {
    return await writeContractAsync({
        address: inputToken.address as `0x${string}`,
        abi: erc20Abi,
        functionName: 'approve',
        args: [routerAddress, amountIn]
    })
}

async function v2Swap(account: string, route: SmartRouter.SwapRoute, writeContractAsync: WriteContractMutateAsync<Config, unknown>, handleSetStep: (newStep: SwapStep) => void, chainId: number) {
    console.error('V2 not implemented')
    handleSetStep(SwapStep.Failure)
}
async function v3Swap(account: string, route: SmartRouter.SwapRoute, writeContractAsync: WriteContractMutateAsync<Config, unknown>, handleSetStep: (newStep: SwapStep) => void, chainId: number, amountIn: BigNumber, referrer: `0x${string}` | undefined | null, publicClient: any, refetchAllowance: (options?: RefetchOptions | undefined) => Promise<QueryObserverResult<bigint, ReadContractErrorType>>, inputToken: PCSToken, outputToken: PCSToken, config: Config) {

    try {
        const bodaSwapAddress = SupportedNetworks[chainId as keyof typeof SupportedNetworks].swapContractAddress
    
        if (!bodaSwapAddress) {
            console.error("No swap contract address set")
            handleSetStep(SwapStep.Failure)
            return
        }    
        
        /*struct v3MultihopTokenInput_SwapParams {
            uint256 amountIn;
            address[] addresses;
            uint24[] poolFees;
            uint32 referralCode;
            uint256 amountOutMin;
        }*/

        // If NOT native token, make sure there is enough approved tokens for the swap
        if (!inputToken.isNativeToken) {
            const currentApprovalData = await refetchAllowance()
            const currentApproval = currentApprovalData.data
        
            if ((currentApproval ?? 0) < BigInt(amountIn.toString())) {
                handleSetStep(SwapStep.ApproveTokens)
        
                const approvalTx = await getApproval(inputToken, writeContractAsync, chainId, BigInt(amountIn.toString()), bodaSwapAddress as `0x${string}`)
        
                handleSetStep(SwapStep.BlockConfirmations)
                await publicClient.waitForTransactionReceipt({confirmations: 4, hash: approvalTx})
            }
        }

        handleSetStep(SwapStep.ConfirmSwap)

        let txHash = '' as `0x${string}`

        // If ETH input
        if (inputToken.isNativeToken) {
            let gas = 0n

            try {
                gas = await publicClient.estimateContractGas({
                    abi: BodaSwapABI,
                    address: bodaSwapAddress as `0x${string}`,
                    functionName: 'v3MultihopEthInput',
                    args: [{
                        addresses: route.trade.routes[0].path.map(x => x.address as `0x${string}`),
                        poolFees: route.trade.swaps[0].route.pools.map(x => (x as any).fee as number),
                        referralCode: 0,
                        amountOutMin: 0n
                    }],
                    value: BigInt(amountIn.toString()),
                    account
                }) as bigint

                gas *= 12n
                gas /= 10n

                console.log("Set gas at ", gas)
            }
            catch (e) {
                console.warn("Couldn't estimate gas, defaulting to MM auto-estimation: ", e)
            }
            
            txHash = await writeContractAsync({
                abi: BodaSwapABI,
                address: bodaSwapAddress as `0x${string}`,
                functionName: 'v3MultihopEthInput',
                args: [{
                    addresses: route.trade.routes[0].path.map(x => x.address as `0x${string}`),
                    poolFees: route.trade.swaps[0].route.pools.map(x => (x as any).fee as number),
                    referralCode: 0,
                    amountOutMin: 0n
                }],
                value: BigInt(amountIn.toString()),
                gas: gas > 0n ? gas : undefined
            })
        }
        // If ETH output
        else if (outputToken.isNativeToken) {
            let gas = 0n

            try {
                gas = await publicClient.estimateContractGas({
                    abi: BodaSwapABI,
                    address: bodaSwapAddress as `0x${string}`,
                    functionName: 'v3MultihopEthOutput',
                    args: [{
                        amountIn: BigInt(amountIn.toString()),
                        addresses: route.trade.routes[0].path.map(x => x.address as `0x${string}`),
                        poolFees: route.trade.swaps[0].route.pools.map(x => (x as any).fee as number),
                        referralCode: 0,
                        amountOutMin: 0n
                    }],
                    account
                }) as bigint

                gas *= 12n
                gas /= 10n

                console.log("Set gas at ", gas)
            }
            catch (e) {
                console.warn("Couldn't estimate gas, defaulting to MM auto-estimation: ", e)
            }

            txHash = await writeContractAsync({
                abi: BodaSwapABI,
                address: bodaSwapAddress as `0x${string}`,
                functionName: 'v3MultihopEthOutput',
                args: [{
                    amountIn: BigInt(amountIn.toString()),
                    addresses: route.trade.routes[0].path.map(x => x.address as `0x${string}`),
                    poolFees: route.trade.swaps[0].route.pools.map(x => (x as any).fee as number),
                    referralCode: 0,
                    amountOutMin: 0n
                }],
                gas: gas > 0n ? gas : undefined
            })
        }
        // If token input and output
        else {
            let gas = 0n

            try {
                gas = await publicClient.estimateContractGas({
                    abi: BodaSwapABI,
                    address: bodaSwapAddress as `0x${string}`,
                    functionName: 'v3MultihopTokenInput',
                    args: [{
                        amountIn: BigInt(amountIn.toString()),
                        addresses: route.trade.routes[0].path.map(x => x.address as `0x${string}`),
                        poolFees: route.trade.swaps[0].route.pools.map(x => (x as any).fee as number),
                        referralCode: 0,
                        amountOutMin: 0n
                    }],
                    account
                }) as bigint

                gas *= 12n
                gas /= 10n

                console.log("Set gas at ", gas)
            }
            catch (e) {
                console.warn("Couldn't estimate gas, defaulting to MM auto-estimation: ", e)
            }

            txHash = await writeContractAsync({
                abi: BodaSwapABI,
                address: bodaSwapAddress as `0x${string}`,
                functionName: 'v3MultihopTokenInput',
                args: [{
                    amountIn: BigInt(amountIn.toString()),
                    addresses: route.trade.routes[0].path.map(x => x.address as `0x${string}`),
                    poolFees: route.trade.swaps[0].route.pools.map(x => (x as any).fee as number),
                    referralCode: 0,
                    amountOutMin: 0n
                }],
                gas: gas > 0n ? gas : undefined
            })
        }
    

        handleSetStep(SwapStep.BlockConfirmations)
    
        const receipt = await publicClient.waitForTransactionReceipt({confirmations: 4, hash: txHash })

        handleSetStep(receipt.status === 'success' ? SwapStep.Success : SwapStep.Failure)
    }
    catch {
        handleSetStep(SwapStep.Failure)
    }
}



export default function useUniswap(inputTokenPcs: PCSToken, outputTokenPcs: PCSToken, inputAmount: BigNumber, outputAmount: BigNumber, referrer: `0x${string}` | undefined | null) {

    const { chainId, connector, address } = useAccount()

    let inputToken: undefined | Core.Token = undefined
    let outputToken: undefined | Core.Token = undefined

    if (chainId !== undefined) {
        inputToken = new Core.Token(chainId, inputTokenPcs.address, inputTokenPcs.decimals, inputTokenPcs.symbol, inputTokenPcs.name, true)
        outputToken = new Core.Token(chainId, outputTokenPcs.address, outputTokenPcs.decimals, outputTokenPcs.symbol, outputTokenPcs.name, true)
    }

    const [provider, setProvider] = useState<unknown | undefined>(undefined)
    useEffect(() => {
        async function assignProvider() {
            try {
                const wagmiProvider = await connector?.getProvider()
    
                if (wagmiProvider) {
                    setProvider(getLibrary(wagmiProvider))
                }
            }
            catch (e) {
                console.warn("Couldn't get provider. Is the wallet connected?", e)
            }
        }
        assignProvider()
    }, [chainId, connector, address])


    const [estimation, setEstimation] = useState(BigNumber.from(0))
    const fetchCountRef = useRef(0)
    const [fetching, setFetching] = useState(false)
    const [lastProtocol, setLastProcol] = useState<'V2' | 'V3' | 'MIXED'>('V2')
    const [lastRoute, setLastRoute] = useState<SmartRouter.SwapRoute | null>(null)
    const [lastPrice, setLastPrice] = useState(0)
    const config = useConfig()

    const bodaSwapAddress = SupportedNetworks[chainId as keyof typeof SupportedNetworks]?.swapContractAddress as `0x${string}`

    const { writeContractAsync } = useWriteContract()
    const publicClient = usePublicClient()
    const { refetch: refetchAllowance } = useReadContract({
        address: inputTokenPcs.address as `0x${string}`,
        abi: erc20Abi,
        functionName: 'allowance',
        args: [address ?? '0x000000000000000000000000000000000000dead', bodaSwapAddress]
    })

    useEffect(() => {
        fetchCountRef.current++;
        setFetching(true)

        async function updateSwap() {
            try {
                if (inputToken === undefined || outputToken === undefined) {            
                    return
                }
                if (!provider) {
                    console.warn("Can't estimate, no provider")
                    return
                }
                if (chainId === undefined || address === undefined) {
                    console.warn("Can't estimate, not connected to any chain")
                    return
                }
        
                const router = new AlphaRouter({
                    chainId: chainId as SmartRouter.ChainId,
                    provider: provider as any
                })
        
                const options: SmartRouter.SwapOptionsSwapRouter02 = {
                    recipient: address,
                    slippageTolerance: new Core.Percent(1, 1),
                    deadline: Math.floor(Date.now() / 1000 + 1800),
                    type: SmartRouter.SwapType.SWAP_ROUTER_02
                }
        
                const rawTokenAmountIn = JSBI.BigInt(inputAmount.toString())
        
                const route = await router.route(
                    Core.CurrencyAmount.fromRawAmount(inputToken, rawTokenAmountIn),
                    outputToken,
                    Core.TradeType.EXACT_INPUT,
                    options
                )
                console.log("hi")

                if (!route || !route.methodParameters) {
                    console.warn("Routing failed")
                    return
                }

                setEstimation(BigNumber.from(route.quote.multiply(10**outputToken.decimals).toExact()))
                setLastProcol(route.route[0].protocol)
                setLastPrice(Number.parseFloat(lastRoute?.trade?.executionPrice?.toSignificant(3) ?? '0'))
                
                if (fetchCountRef.current === 1) {
                    setLastRoute(route)
                }
            }
            catch (e) {
                console.warn("Failed to route swap, error occured: ", e)
                setEstimation(BigNumber.from(0))
                setLastPrice(0)
                setLastRoute(null)
            }
            finally {
                fetchCountRef.current--;
                setFetching(fetchCountRef.current > 1)
            }
        }
        updateSwap()

    }, [inputTokenPcs, outputTokenPcs, inputAmount, address, chainId])
    
    
    return {
        performTrade: (tokens: { tokenA: PCSToken; tokenB: PCSToken; }, baseOnOutput: boolean, handleSetStep: (newStep: SwapStep) => void) => performSwap(provider, chainId, address ?? '', tokens, {inputAmount, outputAmount}, baseOnOutput, writeContractAsync, handleSetStep, referrer, publicClient, refetchAllowance, lastRoute, config),
        lastEstimation: estimation,
        lastProtocol,
        lastPrice,
        estimationIsFetching: fetching
    }
}
