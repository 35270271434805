import { Dispatch, SetStateAction, createContext, useContext, useEffect, useMemo, useState } from "react"
import useReferral from "../useReferral"
import usePopups from "../usePopups"
import { useAccount, useBalance, useReadContract, useReadContracts } from "wagmi"
import TradingStrategyClient from "../../data/retrieval/tradingstrategy/TradingStrategyClient"
import ExampleData from "../../data/devel/example/exampleData"
import { BinanceTokens } from "../../tokens/lists/binanceTokens"
import PCSToken from "../../types/token"
import { TokenLists, isWrappedCoin } from "../../tokens/tokens"
import { BigNumber, ethers } from "ethers"
import SwapStep from "../../types/enums/swapStep"
import UpdatePCSChartData from "../../data/processing/updatePCSChartData"
import CustomTokens from "../../data/customTokens"
import useMultiSwap from "../useMultiSwap"
import { erc20Abi } from "viem"


type SwapContext = {
    activeCurrency: [string, number], 
    chartSeries: ApexAxisChartSeries, 
    chartAvailable: boolean, 
    tokens: {
        tokenA: PCSToken;
        tokenB: PCSToken;
    }, 
    baseToken: string,
    inputValue: BigNumber, 
    outputValue: BigNumber, 
    handleInputValueChanged: (newValue: string) => Promise<void>, 
    handleOutputValueChanged: (newValue: string) => Promise<void>, 
    lastPrice: number, 
    showChart: boolean, 
    setInvertPanel: Dispatch<SetStateAction<boolean>>, 
    setShowChart: Dispatch<SetStateAction<boolean>>, 
    handleTokenSelected: (token: PCSToken) => void, 
    handleTokenClicked: (tokenWasInput: boolean) => void, 
    handleSwapTokens: () => void, 
    handleSettingsClicked: () => void,
    handleRefreshClicked: () => Promise<void>,
    tokenABalance: BigNumber, 
    tokenARawLoading: boolean, 
    tokenAIsRefetching: boolean, 
    tokenBBalance: BigNumber, 
    tokenBRawLoading: boolean, 
    tokenBIsRefetching: boolean, 
    tokenA: PCSToken, 
    tokenB: PCSToken,
    tokenASelected: boolean,
    tokenList: PCSToken[],
    showSettings: boolean, 
    setShowSettings: Dispatch<SetStateAction<boolean>>,
    setActiveCurrency: Dispatch<SetStateAction<[string, number]>>, 
    invertPanel: boolean, 
    performSwap: () => /*performTrade(tokens, baseOnOutput, handleSetStep)*/void,
    lastProtocol: 'V2' | 'V3' | 'MIXED', 
    swapLoading: /*estimationIsFetching*/ boolean,
    setChartSeries: Dispatch<SetStateAction<ApexAxisChartSeries>>,
    onPercentageSet: (value: number) => void
}
const defaultContext: SwapContext = {
    activeCurrency: ['USD', 1],
    chartSeries: [],
    chartAvailable: false,
    tokens: {
        tokenA: TokenLists[56][0],
        tokenB: TokenLists[56][1],
    },
    baseToken: 'N/A',
    inputValue: BigNumber.from(0),
    outputValue: BigNumber.from(0),
    handleInputValueChanged: async () => {},
    handleOutputValueChanged: async () => {},
    lastPrice: 0,
    showChart: false,
    setInvertPanel: () => {},
    setShowChart: () => {},
    handleSwapTokens: () => {},
    handleTokenSelected: () => {},
    handleTokenClicked: () => {},
    handleSettingsClicked: () => {},
    handleRefreshClicked: async () => {},
    tokenABalance: BigNumber.from(0),
    tokenARawLoading: true,
    tokenAIsRefetching: false,
    tokenBBalance: BigNumber.from(0),
    tokenBRawLoading: true,
    tokenBIsRefetching: false,
    tokenA: TokenLists[56][0],
    tokenB: TokenLists[56][1],
    tokenASelected: true,
    tokenList: TokenLists[56],
    showSettings: false,
    setShowSettings: () => {},
    setActiveCurrency: () => {},
    invertPanel: false,
    performSwap: () => {},
    lastProtocol: 'V2',
    swapLoading: false,
    setChartSeries: () => {},
    onPercentageSet: () => {}
}

const SwapContext = createContext<SwapContext>(defaultContext)

export default function useSwap() {
    return useContext(SwapContext)
}

export function SwapContextProvider({children}: {children?: React.ReactNode}) {
    const referrer = useReferral()
    const popups = usePopups()

    // web3 hooks
    const { address, chain } = useAccount()
    const { data: ethBalance } = useBalance({address: address})

    // Panel arrangement
    const [invertPanel, setInvertPanel] = useState(false)
    const [showChart, setShowChart] = useState(false)

    // Data / Charting
    const [dataClient] = useState(new TradingStrategyClient())
    const [chartSeries, setChartSeries] = useState(ExampleData as ApexAxisChartSeries)
    const [chartAvailable, setChartAvailable] = useState(false)
    const [baseToken, setBaseToken] = useState('N/A')

    // Token Selections
    const [tokenList, setTokenList] = useState((BinanceTokens as PCSToken[]))
    const [tokenA, setTokenA] = useState(BinanceTokens[1])
    const [tokenB, setTokenB] = useState(BinanceTokens[0])
    const [tokenASelected, setTokenASelected] = useState(true)
    const tokens = { tokenA, tokenB }
    const handleTokenSelected = (token: PCSToken) => { tokenASelected ? setTokenA(token) : setTokenB(token); popups.close() }
    const handleTokenClicked = (tokenWasInput: boolean) => { setTokenASelected(tokenWasInput); popups.setPopup('TokenSelection') }
    const handleSwapTokens = () => { const tempToken = tokenB; setTokenB(tokenA); setTokenA(tempToken); setBaseOnOutput(currentValue => !currentValue) }


    const balanceResult = useReadContracts({
        allowFailure: true,
        contracts: [
            {
                address: tokenA.address as `0x${string}`,
                abi: erc20Abi,
                functionName: 'balanceOf',
                args: [address ?? '0x000000000000000000000000000000000000dead']
            },
            {
                address: tokenB.address as `0x${string}`,
                abi: erc20Abi,
                functionName: 'balanceOf',
                args: [address ?? '0x000000000000000000000000000000000000dead']
            }
        ]
    })

    useEffect(() => {
        // I know this is a clusterfuck but basically it returns the token list for the current chain ID or if that isn't possible then just the BSC tokens (the home of BODA)
        const tokenList = (TokenLists as any)[chain?.id ?? 56] as PCSToken[] | undefined ?? BinanceTokens
        setTokenList(tokenList)

        setTokenA(tokenList[0])
        setTokenB(tokenList[1])

    }, [chain?.id])


    // User Balances
    const [tokenABalance, setTokenABalance] = useState(BigNumber.from(0))
    const [tokenBBalance, setTokenBBalance] = useState(BigNumber.from(0))
    useEffect(() => {
        if (isWrappedCoin(tokenA.address, chain?.id ?? 56)) {
            setTokenABalance(BigNumber.from(ethBalance?.value?.toString() ?? 0))
        }
        else {
            setTokenABalance(BigNumber.from(balanceResult.data?.[0].result?.toString() ?? 0))
        }
        
        if (isWrappedCoin(tokenB.address, chain?.id ?? 56)) {
            setTokenBBalance(BigNumber.from(ethBalance?.value?.toString() ?? 0))
        }
        else {
            setTokenBBalance(BigNumber.from(balanceResult.data?.[1].result?.toString() ?? 0))
        }
    }, [address, tokenA, tokenB, balanceResult.isFetching, balanceResult.isRefetching, balanceResult.isLoading])
    
    // Token Values
    const [inputValue, setInputValue] = useState(BigNumber.from('1000000000000000000'))
    const [outputValue, setOutputValue] = useState(BigNumber.from('0'))
    const handleInputValueChanged  = async (newValue: string) => { try { setInputValue(ethers.utils.parseUnits(newValue, tokenA.decimals)); setBaseOnOutput(false) } catch { } }
    const handleOutputValueChanged = async (newValue: string) => {} // try { setOutputValue(ethers.utils.parseUnits(newValue, tokenB.decimals)); setInputValue((await estimateTrade(tokens, {inputAmount: inputValue, outputAmount: outputValue}, false)).value); setBaseOnOutput(true) } catch { } }
    const [baseOnOutput, setBaseOnOutput] = useState(false)
    const handleRefreshClicked = async () => {
        balanceResult.refetch()
    }

    const
    {
        performTrade, 
        lastEstimation, 
        lastProtocol, 
        estimationIsFetching,
        lastPrice
    } = useMultiSwap(tokenA, tokenB, inputValue, outputValue, referrer)
    useEffect(() => {
        setOutputValue(lastEstimation)
    }, [lastEstimation])

    /* Utils */
    const [activeCurrency, setActiveCurrency] = useState<[string, number]>(['USD', 1])
    
    const handleSetStep = (step: SwapStep) => {
        switch (step) {
            case SwapStep.OverviewSwap: // This should always be the first step and never be set
                popups.setPopup('ConfirmSwap')
                break;

            case SwapStep.ApproveTokens:
                popups.setPopup('ConfirmApproval')
                break;
            
            case SwapStep.ConfirmSwap:
                popups.setPopup('ApproveSwap')
                break;

            case SwapStep.Failure:
                popups.setPopup('SwapFailed')
                break;

            case SwapStep.Success:
                popups.setPopup('SwapSuccess')
                break;

            case SwapStep.BlockConfirmations:
                popups.setPopup('BlockConfirmation')
                break;
        }
    }

    // Global popups (this shouldn't be here :( )
    const [showSettings, setShowSettings] = useState(false)
    const handleSettingsClicked = () => { setShowSettings(true) }

    const onPercentageSet = (value: number) => {
        setInputValue(tokenABalance.mul(value).div(100))
    }



    // Update charting
    useEffect(() => {
        async function updateChartData() {
            const dateNow = new Date()
            dateNow.setDate(-30)

            const data = await UpdatePCSChartData(dataClient, tokens, chain?.id ?? 56, dateNow)
            
            setBaseToken(data.baseToken)
            setChartSeries(data.priceData)
            setChartAvailable(true)
        }
        showChart && updateChartData()
    }, [tokenA, tokenB, dataClient, showChart, chain?.id])

    const completeTokenList = useMemo(() => {
        return tokenList.concat(CustomTokens)
    }, [CustomTokens, tokenList])


    const value: SwapContext = { 
        activeCurrency, 
        chartSeries, 
        chartAvailable, 
        tokens, 
        baseToken,
        inputValue, 
        outputValue, 
        handleInputValueChanged, 
        handleOutputValueChanged, 
        lastPrice, 
        showChart, 
        setInvertPanel, 
        setShowChart, 
        handleTokenSelected, 
        handleTokenClicked, 
        handleSwapTokens, 
        handleSettingsClicked,
        handleRefreshClicked,
        tokenABalance, 
        tokenARawLoading: balanceResult.isLoading, 
        tokenAIsRefetching: balanceResult.isFetching, 
        tokenBBalance, 
        tokenBRawLoading: balanceResult.isLoading, 
        tokenBIsRefetching: balanceResult.isFetching, 
        tokenA, 
        tokenB,
        tokenASelected, 
        tokenList: completeTokenList,
        showSettings, 
        setShowSettings,
        setActiveCurrency, 
        invertPanel, 
        performSwap: () => performTrade(tokens, baseOnOutput, handleSetStep),
        lastProtocol: lastProtocol, 
        swapLoading: estimationIsFetching,
        setChartSeries,
        onPercentageSet 
    }

    return (
        <SwapContext.Provider value={value}>
            {children}
        </SwapContext.Provider>
    )
}
