import styles from './chartPriceReadout.module.css'


export type ChartPriceReadoutProps = {
    pricePer: string,
    tickerNames: string,
    priceDifferencePercentage: number
    tickerClicked: () => void
}


export const ChartPriceReadout = (props: ChartPriceReadoutProps) => {
    const positiveGain = props.priceDifferencePercentage >= 0

    return (
        <div className={styles.container}>
            <h4 className={styles.pricePer}>{props.pricePer}</h4>
            <p onClick={props.tickerClicked} className={styles.tickerNames}>{props.tickerNames}</p>
            <p className={positiveGain ? styles.priceDifferencePercentagePositive : styles.priceDifferencePercentageNegative}>({positiveGain ? '+' : ''}{props.priceDifferencePercentage.toString()}%)</p>
        </div>
    )
}

export default ChartPriceReadout;
