import React, { createContext, useContext, useState, useEffect } from 'react'

const storageKey = 'bodaswap-jwt'

// Define a type for the context
interface AuthContextType {
    jwt: jwt | null
    setJwt: (jwt: jwt | null) => void
}

type jwt = {
    token: string
    expiry: Date
}

// Create the context
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Custom hook to use the auth context
export default function useAuth() {
    const context = useContext(AuthContext)

    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider')
    }

    return context
}

// AuthProvider component
export const AuthProvider = (props: { children?: React.ReactNode }) => {
    const [jwt, setJwt] = useState<jwt | null>(() => {

        // Initialize jwt from local storage if it exists
        const storedJwt = localStorage.getItem(storageKey)

        return storedJwt ? JSON.parse(storedJwt) : null
    })

    // Update local storage whenever jwt changes
    useEffect(() => {
        if (jwt) {
            localStorage.setItem(storageKey, JSON.stringify(jwt))
        } else {
            localStorage.removeItem(storageKey)
        }
    }, [jwt])

    const authContextValue: AuthContextType = {
        jwt,
        setJwt
    }

    return (
        <AuthContext.Provider value={authContextValue}>
            {props.children}
        </AuthContext.Provider>
    )
}
